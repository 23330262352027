@keyframes rotation
{
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes widenHorizontal {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes progressMotion {
    0% {
      left: -300%;
    }
    
    100% {
      left: 400%;
    }
}

@keyframes turn
{
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes rotate {
	0% {
		transform: rotateZ(0) translate(-50%, -50%);
	}

	100% {
		transform: rotateZ(359deg) translate(-50%, -50%);
	}
}

@keyframes flashing {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes flashingB {
	0% {
		opacity: 1;
	}

	30% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
	  opacity: 0;
	}

	100% {
	  opacity: 1;
	}
}
  
@keyframes fadeOut {
	0% {
	  opacity: 1;
	}
    
	100% {
	  opacity: 0;
	}
}


@keyframes revealUp {
  0% {
    transform: translateY(3.2rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes revealRight {
  0% {
    transform: translateX(-3.2rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes hideRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(3.2rem);
    opacity: 0;
  }
}

@keyframes revealLeft {
  0% {
    transform: translateX(3.2rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes hideLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }

  100% {
    transform: translateX(-3.2rem);
    opacity: 0;
  }
}

@keyframes verticalPipeDown {
  0% {
    height: rem(12.5%);
    transform: translateY(calc(-50% - 16px));
  }

  100% {
    height: rem(25%);
    transform: translateY(-50%);
  }
}

@keyframes horizontalPipeRight {
  0% {
    width: rem(12.5%);
    transform: translateX(calc(-50% - 16px));
  }

  100% {
    width: rem(25%);
    transform: translateX(-50%);
  }
}

@keyframes dropdown {
  0% {
    transform: translateY(rem(-4px));
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes dropup {
  0% {
    transform: translateY(rem(4px));
  }

  100% {
    transform: translateY(0);
  }
}

.instant-enter {
  position: absolute;
  width: calc(100% - #{$transitionRightOffset});
  opacity: 0;
}

.instant-enter-active {
  opacity: 1;
}

.instant-exit {
  opacity: 1;
}

.instant-exit-active {
  opacity: 0;
}

.vertical-enter {
  opacity: 0;
  transform: translateY(25%);
  position: absolute;
  width: 100%;
}

.vertical-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity .4s $animationEasing, transform .2s $animationEasing;
}

.vertical-exit {
  opacity: 1;
}

.vertical-exit-active {
  opacity: 0;
  transition: opacity .2s $animationEasing, transform .2s $animationEasing;
}

.horizontal-enter {
  opacity: 0;
  transform: translateX(25%);
  position: absolute;
  width: 100%;
}

.horizontal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity .4s $animationEasing, transform .2s $animationEasing;
}

.horizontal-exit {
  opacity: 1;
  transform: translateX(0);
}

.horizontal-exit-active {
  transform: translateX(-25%);
  opacity: 0;
  transition: opacity .2s $animationEasing, transform .2s $animationEasing;
}

.horizontal-reverse-enter {
  opacity: 0;
  transform: translateX(-25%);
  position: absolute;
  width: 100%;
}

.horizontal-reverse-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity .4s $animationEasing, transform .2s $animationEasing;
}

.horizontal-reverse-exit {
  opacity: 1;
  transform: translateX(0);
}

.horizontal-reverse-exit-active {
  transform: translateX(25%);
  opacity: 0;
  transition: opacity .2s $animationEasing, transform .2s $animationEasing;
}

// .toolbar-component ~ .toolbar-component,
// .inner-breadcrumb ~ .inner-breadcrumb {
//   display: none;
// }

@keyframes placeHolderShimmer{
  0%{
      background-position: -50vw 0
  }
  100%{
      background-position: 50vw 0
  }
}

@keyframes recedeRight {
  0% {
    transform: scale(1, 1);
    transform-origin: center right;
  }

  100% {
    transform: scale(0, 1);
    transform-origin: center right;
  }
}