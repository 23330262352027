.treeview {
  list-style-type: none;
  padding: rem(14px) rem(4px);
  border: 1px solid rgba($borderColor, 1);
  border-bottom: 1px solid rgba($inputUnderline, 1);
  border-radius: rem(4px);

  ul {
    list-style-type: none;
    padding-left: 0;

    .form-group {
      padding-left: rem(32px);
    }

    ul {
      .form-group {
        padding-left: rem(48px);
      }

      ul {
        .form-group {
          padding-left: rem(64px);
        }

        ul {
          .form-group {
            padding-left: rem(80px);
          }
  
          ul {
            .form-group {
              padding-left: rem(96px);
            }

            ul {
              .form-group {
                padding-left: rem(112px);
              }

              ul {
                .form-group {
                  padding-left: rem(128px);
                }
              }
            }
          }
        }
      }
    }
  }

  .form-group {
    align-items: center;
    padding: rem(5px) rem(10px) rem(5px) rem(16px);
    display: flex;
    border-radius: rem(4px);
    margin-bottom: rem(4px);
    position: relative;
    border: 1px solid rgba($borderColor, 0);
    
    &:focus-visible {
      box-shadow: 0 0 0 1px rgba($white, 1), 0 0 0 3px rgba($gray100, 1);
      z-index: 10;
    }

    &:hover,
    &.active {
      background: rgba($inputBgHover2, .8);
      border: 1px solid rgba($inputBgHover2, 1);
    }

    &:active {
      background: rgba($inputBgHover2, .5);
      border: 1px solid rgba($borderColor, 1);
      label {
        color: rgba($gray100, .7);
      }
    }

    &.active {
      &:hover {
        background: rgba($inputBgHover, .9);
        border: 1px solid rgba($inputBgHover, 1);
      }

      &:active {
        background: rgba($inputBgHover, .5);
        border: 1px solid rgba($borderColor, 1);
        label {
          color: rgba($gray100, .7);
        }
      }
    }

    label {
      padding: 0;
      user-select: none;
    }

    &:hover {
      input[type=checkbox] {
        &:not(:checked):not(:indeterminate) {
          background: rgba($gray1000, 1);

          &:hover {
            background: rgba($gray900, 1);
          }
        }
      }
    }

    &.closed {
      & + ul {
        display: none;
      }

      .icon {
        transform: rotate(-90deg);
        transform-origin: center center;
      }
    }

    .icon {
      font-size: rem(7px);
      text-align: center;
      margin-left: rem(-26px);
      margin-right: rem(4px);
      margin-top: rem(-2px);
      margin-bottom: rem(-2px);
      padding: rem(6px);
      transform: rotate(0);

      &::before {
        display: block;
        width: rem(10px);
        height: rem(10px);
        line-height: rem(10px);
        text-align: center;
      }
    }
  }

  input[type="checkbox"] {
    margin-right: rem(30px);
    height: rem(18px);
    width: rem(18px);
  }
}