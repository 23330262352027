.card {
  background: rgba($cardBg, .5);
  border-radius: rem(8px);
  border: 1px solid rgba($cardBorderColor, .5);
  padding: rem(16px);
  margin-bottom: rem(3px);
  position: relative;

  &.backdrop {
    backdrop-filter: blur(30px);
  }

  &.transparent {
    background: transparent;
    border: 0;
    padding: 0;
  }

  .flex-row {
    width: auto;
  }

  &.flex-v-top {
    .content {
      align-self: flex-start;
    }
  }
  
  .card {
    background: rgba($cardBorderColor, .2);
    margin-bottom: 0;
    border-radius: rem(16px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  tfoot,
  thead {
    .card {
      border-radius: rem(8px);

      .ghost-button {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &.p-large {
    padding: rem(32px);
  }
}