input[type=image] {
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  overflow: visible;
  position: relative;
  transition: .2s $animationEasing all, 0.01s linear box-shadow;
  z-index: 9;
  user-select: none;

  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: .3;
  }

  &:active {
    transform: scale(.85);
  }

  &:focus-visible {
    box-shadow: $focusOutline;
    z-index: 10;
  }
}