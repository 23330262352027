@mixin tabular {
    table {
      counter-reset: numbering;
      width: 100%;
      // margin: rem(16px) 0;
      margin: 0;
      border-collapse: collapse;
  
      table {
        &:last-child {
          margin-bottom: rem(16px);
        }
      }
  
      &:last-child {
        margin-bottom: 0;
      }
  
      .thumbnail {
        margin: 0;
        &:not(:last-child) {
          margin-right: rem(8px);
        }
      }
  
      .text-chunk {
        color: solidColor($faintText);
        font-size: rem(14px);
      }
  
      thead,
      tfoot {
        position: sticky;
        top: 0;
        z-index: 11;
  
        .primary-button,
        .secondary-button,
        .ghost-button,
        .link-button,
        .card-button {
          margin-top: rem(-4px);
          margin-bottom: rem(-5px);
        }
  
        th {
          background: solidColor($tableHeadBg);
          z-index: 10;
          white-space: nowrap;
        }
        
        tr:first-child {
          th {
            &:first-child {
              border-top-left-radius: rem(8px);
            }
  
            &:last-child {
              border-top-right-radius: rem(8px);
            }
          }
        }
  
        tr:last-child {
          th {
            &:first-child {
              border-bottom-left-radius: rem(8px);
            }
  
            &:last-child {
              border-bottom-right-radius: rem(8px);
            }
          }
        }
      }
  
      tfoot {
        top: auto;
        bottom: 0;
      }
  
      tbody {
        tr {
          &:not(.uncounted) {
            counter-increment: numbering;
          }
  
          &.uncounted {
            td {
              &:first-child {
                width: auto;
                &::before {
                  display: none;
                }
              }
            }
          }
  
          &:hover,
          &.active {
            position: relative;
            z-index: 1;
  
            & > th,
            & > td {
              background-color: rgba($tableRowBg, .5);
              border-color: transparent;              
            }
            
            & > th:first-child,
            & > td:first-child {
              border-top-left-radius: rem(8px);
              border-bottom-left-radius: rem(8px);
              overflow: visible;
            }
            
            & > th:last-child,
            & > td:last-child {
              border-top-right-radius: rem(8px);
              border-bottom-right-radius: rem(8px);
            }
          }
  
          &.active {
            & + tr {
              & > th,
              & > td {
                border: 0
              }
            }
          }
  
          &:nth-child(n + 7) {
            .overlap-list {
              bottom: 100%;
              top: auto;
              animation-name: dropup;
            }
  
            .option-list {
              bottom: calc(100% - 6px);
              top: auto;
              animation-name: dropup;
            }
          }
        }
      }
      
      th,
      td {
        &:not(.spacer-cell) {
          height: rem(18px);
          padding: rem(8px);
          font-size: rem(15px);
          text-align: left;
          transition: .2s $animationEasing background-color,
                      .2s $animationEasing border-color;
          font-weight: 400;
          letter-spacing: -.015em;
          
          &.text-top {
            vertical-align: top;
          }
          
          &.text-bottom {
            vertical-align: bottom;
          }
          
          &.text-center {
            text-align: center;
          }
          
          &.text-right {
            text-align: right;
          }
          
          &.narrow-p-lr {
            padding-left: rem(4px);
            padding-right: rem(4px);
          }
        }
      }
  
      th {
        &:not(.spacer-cell) {
          font-weight: 500;
        }
      }
  
      td {
        .na {
          background: rgba($gray700, .9);
          border-radius: rem(4px);
          display: inline-block;
          width: rem(32px);
          height: rem(8px);
        }
  
        & > .fa,
        & > .fas,
        & > .far {
          font-size: rem(19px);
        }
  
        &:first-child {
          width: rem(30px);
  
          &::before {
            content: counter(numbering) ".";
            margin-left: rem(8px);
            margin-right: rem(4px);
          }
        }
      }
  
      .spacer-cell {
        padding: 0;
      }
  
      .ghost-button-group {
        transform-origin: center right;
        display: inline-block;
      }
  
      thead {
        th {
          border-bottom: 0;
        }
      }
  
      tbody {
        td {
          position: relative;
        }
      }
    }
  
    &.unlined {
      td {
        border: 0 !important;
      }
    }
  
    table {
      &.unlined {
        tr {
          td {
            &:first-child {
              border-top-left-radius: rem(8px);
              border-bottom-left-radius: rem(8px);
              overflow: visible;
            }
            
            &:last-child {
              border-top-right-radius: rem(8px);
              border-bottom-right-radius: rem(8px);
            }
          }
          
          
          &:hover {
            td {
              background-color: solidColor($cardBgHover);
            }
          }
        }
      }
    }
  
    &.unordered {
      td {
        &:first-child {
          width: auto;
  
          &::before {
            display: none;
          }
        }
      }
    }
  
    .table-loading {
      tbody {
        min-height: 32px;
        display: block;
      }
  
      thead {
        th {
          * {
            opacity: 0;
          }
        }
      }
    }
  }