@mixin button-icon {
    .icon {
      font-size: 1em;
      line-height: 1em;
      margin: 0 rem(5px);
  
      &:first-child {
        margin-left: 0;
      }
  
      &:last-child {
        margin-right: 0;
      }
    }
  }