.card-button {
  align-items: center;
  background: rgba($cardBg, .5);
  color: rgba($gray100, 1);
  border-radius: rem(8px);
  font-size: rem(15px);
  border: 1px solid rgba($cardBorderColor, .5);
  display: flex;
  padding: rem(16px);
  position: relative;
  text-align: left;
  transition: .2s $animationEasing all, 0.01s linear box-shadow; 
  width: 100%;
  user-select: none;
  cursor: pointer;

  &.disabled,
  &[disabled],
  a:not([href]) {
    pointer-events: none;
    background: rgba($inputBg, .2);
    opacity: .6;
  }

  .icon {
    margin-right: rem(8px);
    padding-right: 0.6rem;
  }

  .text-container {
    flex-basis: 100%;
  }
  
  &:focus-visible {
    box-shadow: $focusOutline;
    z-index: 1000;
  }

  &:hover {
    background: rgba($cardBgHover, .5);
  }

  &:active {
    background: rgba($cardBgActive, .5);
    border: 1px solid rgba($borderColorFocus, 1);
  }
}