.slick-slider .slick-list,
.slick-slider .slick-track {
    border-radius: rem(8px);
    margin: 0 rem(-4px);
    
    .slick-slide {
        padding: 0 rem(8px);
    }
}

.slick-slider,
.slick-slider.slick-dotted {
    margin-bottom: rem(40px);
}

.slick-autoplay-toggle-button,
.slick-autoplay-toggle-button:focus {
    color: rgb(var(--primary));
    opacity: 1;
}

.slick-autoplay-toggle-button:focus-visible {
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
    box-shadow: $focusOutline;
    border-radius: rem(4px);
}

.slick-dots li button .slick-dot-icon,
.slick-dots li.slick-active button .slick-dot-icon,
.slick-dots li button:focus .slick-dot-icon::before,
.slick-dots li.slick-active button:focus .slick-dot-icon {
    color: transparent;
}

.slick-autoplay-toggle-button .slick-play-icon::before,
.slick-autoplay-toggle-button .slick-pause-icon::before {
    vertical-align: middle;
}

.slick-dots li button .slick-dot-icon::before {
    background-color: rgb(var(--primary));
    border-radius: 500px;
}

.slick-dots li button:focus-visible .slick-dot-icon::before,
.slick-dots li.slick-active button:focus-visible .slick-dot-icon {
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
    box-shadow: $focusOutline;
    border-radius: 500px;
}