@mixin holiday-v-pipe {
    @each $day, $color in $dayTypes {
      &.#{$day} {
        position: relative;
  
        &::before {
          content: "";
          background: solidColor($color);
          bottom: 0;
          display: block;
          left: 50%;
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          width: 4px;
          z-index: 0;
        }
      }
    }
  }