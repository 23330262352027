.thumbnail {
	background: rgba($thumbnailBg, 1);
	border-radius: 50% 50% 50% rem(8px);
	display: inline-flex;
	width: rem(48px);
	height: rem(48px);
	object-fit: contain;
	justify-content: center;
	align-items: center;
	vertical-align: bottom;
	
	&.padded {
		padding: rem(8px);

		&-sm {
			padding: rem(4px);

			&:first-child {
				margin-left: rem(-4px);
			}
		}
	}

	&.smallest {
		width: rem(24px);
		height: rem(24px);
	}

	&.smaller {
		width: rem(32px);
		height: rem(32px);
	}

	&.small {
		width: rem(40px);
		height: rem(40px);
	}

	&.medium {
		width: rem(64px);
		height: rem(64px);
	}

	&.large {
		width: rem(96px);
		height: rem(96px);
	}

	&.release {
		border-radius: 0;
	}

	&.radius {
		border-radius: 3px;
	}

	&.round {
		border-radius: 500px;
	}

	&.contrast {
		background: rgba($gray100, 1);
	}

	&.no-bg {
		background: transparent;
	}
}