.html-control {
    figure {
      margin: 0;
    }
    
    .html-control-toolbar {
      background: rgba($cardBgActive, 1);
      border-radius: 0.375rem;
      border: 1px solid rgba($borderColor, 1);
      border-bottom: 0;
      border-radius: rem(5px) rem(5px) 0 0;
      margin: 0;
    }
  
    .html-control-wrapper {
      margin: 0 0 1rem 0;
    }
    
  
    .public-DraftEditor-content {
      width: 100%;
      margin: 0;
      border-radius: 0 0 rem(5px) rem(5px);
      border-top: 0;
      padding: rem(1px) rem(16px) rem(1px) rem(16px);
  
      &:focus {
        padding-bottom: 0;
      }
  
      span[style*="font-family: monospace"] {
        color: rgba($primary, 1) !important;
        background: rgba($appBgColor, 1) !important;
        border: 1px solid rgba($borderColor, 1) !important;
        padding: rem(4px) rem(8px) !important;
        border-radius: rem(5px) !important;
      }
    }
  
    .rdw-dropdown-optionwrapper {
      background: rgba($inputBg, 1);
      border: 1px solid rgba($borderColor, 1);
      box-shadow: 0 rem(2px) rem(12px) rgba($contrastGlow, .7);
      border-radius: rem(5px);
      padding: rem(6px);
      width: auto;
      min-width: rem(180px);
  
      &:hover {
        background: rgba($inputBg, 1);
        border: 1px solid rgba($borderColor, 1);
        box-shadow: 0 rem(2px) rem(12px) rgba($contrastGlow, .7);
      }
    }
  
    .rdw-dropdownoption-default {
      padding: rem(6px);
    }
  
    .rdw-dropdownoption-active,
    .rdw-dropdownoption-highlighted {
      background: rgba($inputBgHover, 1);
      border-radius: rem(5px);
    }
  
    .rdw-dropdown-wrapper {
      border: 0;
      padding: 0;
      box-shadow: none;
      background: transparent;
      margin: 0 rem(2px) 0 0;
      height: rem(32px);
  
      &:hover {
        background: transparent;
        box-shadow: none;
      }
  
      .rdw-dropdown-carettoopen,
      .rdw-dropdown-carettoclose {
        border-top-color: rgba($black, 1);
        top: 50%;
        transform: translateY(-50%);
        right: rem(8px);
      }
  
      .rdw-dropdown-carettoclose {
        border-bottom-color: rgba($black, 1);
      }
    }
  
    .rdw-dropdown-selectedtext {
      background: rgba($inputBg, 1);
      border: 1px solid rgba($borderColor, 1);
      box-shadow: none;
      font-family: $primaryType;
      font-size: rem(15px);
      border-radius: rem(5px);
  
      &:hover {
        background: rgba($inputBgHover, 1);
      }
    }
  
    .rdw-dropdown-wrapper {
      color: rgba($gray100, 1);
    }
  
    .rdw-option-wrapper {
      background: rgba($inputBg, 1);
      border: 1px solid rgba($borderColor, 1);
      box-shadow: none;
      padding: rem(8px);
      height: rem(32px);
      width: rem(32px);
      border-radius: rem(5px);
      margin: 0 rem(2px) 0 0;
  
      &:hover {
        background: rgba($inputBgHover, 1);
        box-shadow: none;
      }
  
      img {
        filter: invert(1);
      }
    }
  }