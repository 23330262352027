* {
	transition: .2s ease scrollbar-color;
    scrollbar-color: rgba($gray100,.3) rgba($gray700, .3); 
	scrollbar-width: thin;
	  
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	
	&::-webkit-scrollbar-track {
		transition: .2s ease all;
		background: rgba($gray700, .3);
		border-radius: 20px;
	}
	
	&::-webkit-scrollbar-thumb {
		transition: .2s ease all;
		background-color: rgba($gray100,.3);
		border-radius: 20px;
		border: transparent;
	}

    &:hover {
      scrollbar-color: rgba($gray100,.5) rgba($gray700, .5);

      &::-webkit-scrollbar-track {
        background: rgba($gray700, .5);
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: rgba($gray100,.5);
      }
    }
}

.unselectable {
	user-select: none;
}

.invisible {
	opacity: 0;
}

.hover-visible {
	&:hover {
		opacity: 1;
	}
}

.hover-visible-trigger {
	&:hover {
		.hover-visible {
			opacity: 1;
		}
	}
}

.spinner
{
  vertical-align: middle;
  animation: rotation 1.35s linear infinite;
  stroke: currentColor;
  margin-left: -4px;
  margin-top: -4px;
  width: 32px;
  height: 32px;
}

.circle
{
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: turn 1.35s ease-in-out infinite;
  animation: turn 1.35s ease-in-out infinite;
}

.loader-border {
	position: relative;
	z-index: 0;
	overflow: hidden;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: 50%;
		top: 50%;
		width: 100vw;
		height: 100vw;
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(rgba($cardBgHover, 1), rgba($cardBgHover, 1));
		animation: rotate 3s linear infinite;
		transform-origin: top left;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 4px;
		top: 4px;
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		background: rgba($cardBgActive);
		border-radius: rem(6px);
	}

	&.card {
		background: rgba($cardBg, .25);
	}
}

.card {
	.flashing-border {
		&::after {
			border-radius: rem(12px);
		}
	}
}


tfoot,
thead {
  .card {
	&.flashing-border {
		&::after {
			border-radius: rem(6px);
		}
	}
  }
}

.flashing-border {
	position: relative;
	z-index: 0;
	// overflow: hidden;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		border-radius: rem(8px);
		display: block;
		background-color: rgba($primary, .9);
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		// animation: flashing 3s linear infinite;
		animation: flashingB 5s linear infinite;

	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 4px;
		top: 4px;
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		background: rgba($cardBgActive, 1);
		border-radius: rem(6px);
	}
}

.progress-bar {
	height: 0;
	// height: rem(5px);
	width: rem(280px);
	max-width: 100%;
	border-radius: rem(10px);
	background: rgba($gray700, 1);
	position: relative;
	animation: .4s fadeIn linear forwards;
	counter-increment: section;
	transition: .2s ease opacity;
	margin-left: 0;
	position: fixed;
  
	.steps {
	  position: absolute;
	  top: 50%;
	  left: calc(100% + rem(5px));
	  transform: translateY(-50%);
	}
	
	&.hide-it {
	  animation: .4s fadeOut .8s linear forwards;
	}
  
	.progress {
	  background: currentColor;
	  color: rgba($gray100, 1);
	  border-radius: rem(10px);
	  height: rem(5px);
	  position: absolute;
	  top: 50%;
	  box-shadow: 0 rem(3px) rem(12px) rem(-3.2px) currentColor;
	  transform: translateY(-50%);
	  overflow: hidden;
	  transition: .4s $animationEasing width;
  
	  &.overlap {
		color: rgba($primary, 1);
		transition-delay: .2s;
	  }
  
	  &::after {
		content: "";
		display: block;
		height: rem(10px);
		width: 0;
		box-shadow: 0 0 rem(80px) rem(50px) rgba($gray800, 1);
		animation: 4s progressMotion linear infinite;
		position: absolute;
		overflow: hidden;
		left: 0;

	  }
	}
  }

  .reveal-up-1 {
	animation: 0.1s $animationEasing revealUp forwards;
	opacity: 0;
  }
  
  .reveal-up-2 {
	animation: 0.5s $animationEasing 0.15s revealUp forwards;
	opacity: 0;
  }
  
  .reveal-up-3 {
	animation: 0.5s $animationEasing 0.2s revealUp forwards;
	opacity: 0;
  }
  
  .reveal-right {
	animation: 0.5s $animationEasing 0.1s revealRight forwards;
	opacity: 0;
  }
  
  .reveal-right-1 {
	animation: 0.1s $animationEasing revealRight forwards;
	opacity: 0;
  }
  
  .reveal-right-2 {
	animation: 0.5s $animationEasing 0.15s revealRight forwards;
	opacity: 0;
  }
  
  .reveal-right-3 {
	animation: 0.5s $animationEasing 0.2s revealRight forwards;
	opacity: 0;
  }

  .reveal-right {
	animation: 0.5s $animationEasing 0.1s revealRight forwards;
	opacity: 0;
  }
  
  .reveal-left-1 {
	animation: 0.1s $animationEasing 0.2s revealLeft forwards;
	opacity: 0;
  }
  
  .reveal-left-2 {
	animation: 0.5s $animationEasing 0.2s revealLeft forwards;
	opacity: 0;
  }
  
  .reveal-left-3 {
	animation: 0.5s $animationEasing 0.2s revealLeft forwards;
	opacity: 0;
  }

  .hide-right-1 {
	animation: 0.1s $animationEasing 0.2s hideRight forwards;
	opacity: 1;
  }
  
  .hide-right-2 {
	animation: 0.5s $animationEasing 0.2s hideRight forwards;
	opacity: 1;
  }
  
  .hide-right-3 {
	animation: 0.5s $animationEasing 0.2s hideRight forwards;
	opacity: 1;
  }

  .left,
  .right,
  .top,
  .bottom,
  .center {
	&,
	&-tight {
		position: absolute !important;
		z-index: 1;
	}
  }

  .v-center {
	position: absolute !important;
	z-index: 1;
  }

  .left {
    left: rem(16px);

	&-tight {
		left: rem(8px);
	}
  }
  
  .right {
    right: rem(16px);
	
	&-tight {
		right: rem(8px);
	}
  }
  
  .top {
    top: rem(16px);

	&-tight {
		top: rem(8px);
	}
  }

  .bottom {
    bottom: rem(16px);

	&-tight {
		bottom: rem(8px);
	}
  }

  .center {
    left: 50%;
    transform: translateX(-50%);
  }

  .v-center {
    top: 50%;
    transform: translateY(-50%);

    &.center {
      transform: translate(-50%);
    }
  }

// select[multiple]:not(.horizontal),
// .v-scroll,
// .inner-v-scroll,
// .nav-v-scroll,
// .context-menu,
// .comment-body {
//     transition: .2s ease scrollbar-color;
//     scrollbar-color: rgba($gray100,.3) rgba($gray700, .3); 
// 	scrollbar-width: thin;
	  
// 	&::-webkit-scrollbar {
// 		width: 3px;
// 		&:hover {
// 			width: 6px;
// 		}
// 	}
	
// 	&::-webkit-scrollbar-track {
// 		transition: .2s ease all;
// 		background: rgba($gray700, .3);
// 		border-radius: 20px;
// 	}
	
// 	&::-webkit-scrollbar-thumb {
// 		transition: .2s ease all;
// 		background-color: rgba($gray100,.3);
// 		border-radius: 20px;
// 		border: transparent;
// 	}

//     &:hover {
//       scrollbar-color: rgba($gray100,.5) rgba($gray700, .5);

//       &::-webkit-scrollbar-track {
//         background: rgba($gray700, .5);
//       }
      
//       &::-webkit-scrollbar-thumb {
//         background-color: rgba($gray100,.5);
//       }
//     }
// }

.v-scroll,
.inner-v-scroll,
.nav-v-scroll,
.context-menu {
	overflow: auto;
}

.hide-scroll {
	scrollbar-color: rgba($gray100,0) rgba($gray700, 0); 
	scrollbar-width: none;
}

.v-scroll {
	max-height: calc(100vh - 42px);
	padding: 3px 0 8px 3px;
	// margin: -3px 0 0 -3px;
	
	.tableview-component,
	.timetrack-component,
	.booking-component,
	.clocking-component {
		table {
			thead {
				top: -3px;
			}

			tfoot {
				bottom: -8px;
			}
		}
		
		.inner-v-scroll {
			table {
				thead {
					top: 0;
				}

				tfoot {
					bottom: 0;
				}
			}
		}
	}

	.breadcrumb-component ~ & {
		max-height: calc(100vh - 77px);
	}

	& > .scroll-content {
		margin-top: rem(-2px);
		padding: 0 rem(13px) 0 rem(13px);

		.sidebar-component ~ .main-component & {
			padding-left: 0;
		}

		@media screen and (max-width: $mobileMax) {
			padding-bottom: rem(64px);
		}
	}

	.horizontal & {
		margin-top: 0;
		max-height: calc(100vh - 64px);
		border-radius: 8px;
	}
}

.blurred-item {
	user-select: none;
	filter: blur(5px) grayscale(100%);
	opacity: .5;
	display: inline-block;

	&.deblur {
		animation: .2s $animationEasing deblur forwards;
	}

	&.blur {
		animation: .2s $animationEasing blur forwards;
	}
}

@keyframes deblur {
	0% {
		transform: translateX(0);
		opacity: .5;
		filter: blur(5px) grayscale(100%);
	}

	50% {
		transform: translateX(1rem);
		opacity: 0;
		filter: blur(5px) grayscale(100%);
	}

	51% {
		transform: translateX(-1rem);
		filter: none;
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		filter: none;
		opacity: 1;
	}
}

@keyframes blur {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	50% {
		transform: translateY(-100%);
		opacity: 0;
	}

	51% {
		transform: translateY(100%);
		filter: none;
		opacity: 0;
		filter: blur(5px) grayscale(100%);
	}

	100% {
		transform: translateY(0);
		filter: none;
		opacity: .5;
		filter: blur(5px) grayscale(100%);
	}
}

.blurred-children {
	span,
	img {
		user-select: none;
		filter: blur(5px) grayscale(100%);
		opacity: .5;
	}
}

.inner-v-scroll {
	border-radius: rem(8px);
	max-height: calc(100vh - 152px);
	padding-right: rem(4px);
	margin-right: rem(-4px);

	@media screen and (max-width: $tabletMax) {
		max-height: calc(100vh - 172px);
		width: 100%;
		overflow-x: auto;
	}

	@media screen and (max-width: $mobileMax) {
		max-height: none !important;
		width: auto;
		overflow: auto;
	}

	&.no-shadow {
		&::before {
			display: none;
		}
	}
}

.nav-v-scroll {
	height: 100%;
	max-height: 100vh;
}

.sr-only {
	font-size: 0;
	color: transparent;
}

.up,
.down {
	position: relative;
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;

	.static,
	.moving {
		height: rem(24px);
	}

	.moving {
		position: absolute;
		left: 0;
		top: 0;
		transform: translateY(0%);
		opacity: 1;
		animation: 2s ease ghostDown infinite;
	}
}

.up {
	padding-top: rem(6px);
	.moving {
		animation-name: ghostUp;
		top: rem(6px);
	}
}

.fill {
	width: 100%;
}

.flow-v {
	flex-direction: column;
}

.v-fill {
	display: flex;
	align-items: stretch;
	flex-direction: row;
}

.m-b-0 {
	margin-bottom: 0;
}


.show-tablet {
	@media screen and (min-width: $desktopMin) {
		display: none !important;
	}
}

@keyframes ghostUp {
	0% {
		transform: translateY(0%);
		opacity: 1;
	}

	100% {
		transform: translateY(-50%);
		opacity: 0;
	}
}

@keyframes ghostDown {
	0% {
		transform: translateY(0%);
		opacity: 1;
	}

	100% {
		transform: translateY(50%);
		opacity: 0;
	}
}

.flex-h-start {
	justify-content: flex-start !important;
}

.flex-h-spread {
	justify-content: space-between !important;
}

.flex-h-start-self {
	margin-right: auto !important;
}

.flex-h-center-self {
	margin-left: auto !important;
	margin-right: auto !important;
}

.flex-h-end-self {
	margin-left: auto !important;
}

.flex-v-start-self {
	margin-top: auto !important;
}

.flex-v-center-self {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.flex-v-end-self {
	margin-bottom: auto !important;
}