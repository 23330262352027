.toaster-component {
  width: 100%;
  max-width: 440px;
  position: fixed;
  bottom: 0;
  right: rem(8px);
  display: flex;
  flex-direction: column-reverse;
  z-index: 10000;

  .toaster-entry {
    width: 100%;
    margin-bottom: rem(8px);

    &.card {
      background: rgba($cardBg, 1);
    }
  }
}