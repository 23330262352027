@mixin calendar {
    table {
      thead {
        z-index: 30;
      }
  
      tfoot {
        z-index: 31;
      }
      
      .comment-box {
        position: relative;
        top: calc(100% + 4px);
        left: 0;
        z-index: 20;
        min-width: 300px;
        min-height: 100px;
        max-height: 250px;
        transition: none;
        opacity: 0;
        pointer-events: none;
  
        &:focus {
          pointer-events: all;
          opacity: 1;
        }
      }
  
        th.month-day:not(.spacer-cell) {
          text-align: center;
          font-weight: 500;
          min-width: rem(42px);
        }
  
        .lead-column {
          width: 100%;
        }
  
        th {
          &.today {
            box-shadow: inset 0 0 0 200px solidColor($todayColor);
            border-radius: rem(8px);
            color: solidColor($white);
          }
  
          .tooltip {
            cursor: default;
  
            .tooltip-content {
              position: absolute;
              display: block;
              background: solidColor($gray100);
              border-radius: rem(8px);
              left: 0;
              top: calc(100% + 4px);
              box-shadow: 0 0 50px rgba($boxShadow, .5);
              color: solidColor($white);
              font-size: rem(12px);
              letter-spacing: normal;
              padding: rem(8px);
              text-align: left;
              z-index: 10;
              display: none;
              user-select: none;
              
              &::first-letter {
                text-transform: capitalize;
              }
            }
  
            &:hover {
              .tooltip-content {
                display: block;
              }
            }
          }
  
          @each $day, $color in $dayTypes {
            &.#{$day} {
              .inner-text {
                border-radius: 500px;
                color: solidColor($blackStatic);
                background: solidColor($color);
                display: inline-block;
                width: rem(24px);
                position: relative;
              }
            }
          }
  
          @each $day, $color in $dayTypesContrast {
            &.#{$day} {
              &.today {
                .inner-text {
                  box-shadow: 0 0 0 2px solidColor($color);
                }
              }
            }
          }
        }
              
        tbody {
          z-index: 29;
          position: relative;
          
          tr {
            &:hover {
              position: static;
  
              td {
                &.weekend {
                  background-color: solidColor($weekendBgHover);
                }
              }
            }
  
            &:nth-last-child(-n+6):not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)) {
              td {
                &:focus-within {
                  .inner-content {
                    top: auto;
                    bottom: rem(3px);
                    animation: .2s dropup ease forwards;
                  }
                }
  
                .comment-badge {
                  top: auto;
                  bottom: calc(100% - 25px);
  
                  &::after {
                    bottom: rem(-20px);
                  }
                }
              }
            }
          }
        }
  
        td {
          position: static;
          border: 0;
  
          .status {
            position: absolute;
            left: rem(-3px);
            bottom: rem(-8px);
            z-index: 100;
            transform: scale(.5);
            color: solidColor($white);
            background: solidColor($gray100);
            border-radius: 500px;
            padding: rem(4px);
            color: solidColor($white);
            width: rem(40px);
            height: rem(40px);
            text-align: center;
  
            .spinner {
              margin-left: 0;
              margin-top: 0px;
            }
  
            &.error {
              background: solidColor($danger);
              color: solidColor($whiteStatic);
            }
  
            .error-icon {
              line-height: rem(32px);
              font-size: rem(18px);
            }
  
            .success-icon {
              line-height: rem(32px);
              font-size: rem(24px);
            }
          }
  
          &.weekend {
            background-color: solidColor($weekendBg);
          }
  
          &.today {
            .content {
              &:not(:focus-within) {
                .inner-content {
                  .hour-box {
                    border-color: rgba($primary, .5);
                  }
                }
              }
            }
          }
  
          .inner-content {
            position: absolute;
            
            .comment-box {
              display: none;
            }
            
            .hour-box {
              position: relative;
              padding: rem(2px) rem(4px) rem(20px);
              line-height: rem(20px);
              height: rem(48px);
              border-radius: rem(9px);
              font-size: rem(13px);
              text-align: center;
  
              &[disabled],
              &.disabled {
                opacity: 1;
              }
  
              &:focus {
                z-index: 21;
  
                & + .comment-box {
                  pointer-events: all;
                  opacity: 1;
                }
              }
            }
          }
  
          .content {
            position: relative;
            z-index: 2;
  
            &:hover {
              z-index: 19;
            }
  
            &:focus-within {
              z-index: 20;
            }
  
            &:focus-within {
              .inner-content {
                border: 1px solid rgba($borderColor, .6);
                background: rgba($cardBgActive, 1);
                border-radius: rem(8px);
                padding: rem(16px);
                text-align: left;
                box-shadow: 0 rem(2px) rem(12px) rgba($contrastGlow, .7);
                animation: .2s dropdown ease forwards;
                top: rem(3px);
                left: rem(3px);
                
                .hour-box {
                  max-width: rem(100px);
                  margin-bottom: rem(8px);
                  border-radius: rem(5px);
                  padding: rem(1px) rem(16px) rem(1px) rem(16px);
                  height: rem(36px);
                  text-align: left;
                }
  
                .comment-box {
                  display: block;
                }
  
                .comment-badge {
                  display: none;
                }
  
                .status {
                  top: rem(8px);
                  right: rem(8px);
                  left: auto;
                  bottom: auto;
                  width: rem(40px);
                  height: rem(40px);
                }
              }
            }
  
            &:not(:focus-within) {
              .inner-content {
                .hour-box {
                  background: rgba($cardBgActive, 1);
                  border: 1px solid rgba($borderColor, .6);
  
                  //to fix same disabled state for both dark and light mode,
                  //the background needs to be separated from the cardBgActive variable
                  &[disabled],
                  &.disabled {
                    background: rgba($cardBgActive, 1);
                  }
                  
                  &::placeholder {
                    opacity: 0;
                  }
                }
              }
            }
  
            &.active {
              z-index: 21;
            }
          }
  
          .comment-badge {
            position: absolute;
            top: calc(100% - 3px);
            z-index: 11;
            left: 50%;
            
            &::after {
              content: '\f055';
              font-weight: 700;
              font-family: $iconType;
              position: absolute;
              bottom: 100%;
              left: 0;
              transform: translateX(-0.5em);
              font-size: rem(16px);
            }
  
            .comment-body {
              background: solidColor($gray100);
              border-radius: rem(8px);
              box-shadow: 0 0 50px rgba($boxShadow, .5);
              color: solidColor($white);
              font-size: rem(12px);
              letter-spacing: normal;
              min-width: 240px;
              padding: rem(8px);
              position: relative;
              text-align: left;
              transform: translateX(-0.5em);
              z-index: 10;
              display: none;
              white-space: pre-line;
              max-height: rem(300px);
              overflow: auto;
            }
            
            &:hover {
              .comment-body {
                display: block;
              }
            }
          }
  
          
          &:nth-child(n+18) {
            &:focus-within {
              .inner-content {
                right: rem(3px);
                left: auto;
                text-align: right;
              }
            }
            
            .comment-badge {
              .comment-body {
                transform: translateX(-100%) translateX(0.5em);
              }
            }
          }
        }
      }
  }