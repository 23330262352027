.avatar-tile {
    position: relative;
    padding: rem(4px);
  
    input[type=radio] {
      position: absolute;
      opacity: 0;
      pointer-events: none;
  
      & + label {
        border-radius: 500px;
        width: rem(32px);
        height: rem(32px);
        display: block;
        font-size: 0;
        background: rgba($thumbnailBg, 1);
        cursor: pointer;
        transition: .2s $animationEasing all, 0.01s linear box-shadow;
        overflow: hidden;
        position: relative;
  
        &.avatar-stack {
          display: inline-block;
          position: relative;
          cursor: pointer;
          padding: 0;
          width: rem(96px);
          height: rem(96px);
          border-radius: 8rem 8rem 8rem 2rem;
      
          > img {
            width: 100%;
            border-radius: 8rem 8rem 8rem 2rem;
            position: relative;
            display: block;
          }
          
          .avatar-shirt,
          .avatar-hair,
          .avatar-beard,
          .avatar-eyes,
          .avatar-mouth,
          .avatar-eyewear {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
          }
        }
        
        img {
          width: 100%;
          height: auto;
        }
      }
  
      &:checked + label {
        .selection-helper {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: 500px;
          box-shadow: inset 0 0 0 4px rgba($gray100, .6);
          z-index: 10;

          &::after {
            position: absolute;
            display: block;
            content: "";
            box-shadow: inset 0 0 0 2px rgba($gray100, .8);
            border-radius: 500px;
            top: 3px;
            bottom: 3px;
            right: 3px;
            left: 3px;
          }
        }
  
        &.avatar-stack {
          .selection-helper {
            border-radius: 8rem 8rem 8rem 2rem;

            &::after {
              border-radius: 8rem 8rem 8rem 2rem;
            }
          }
        }
  
        img {
          position: relative;
          z-index: 0;
        }
      }
  
      &:focus-visible + label {
        box-shadow: 0 0 0 2px rgba($white, 1), 0 0 0 4px rgba($gray100, 1);
      }
    }
  }