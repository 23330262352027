.CONDUIT {
  &.blue-accent {
    --primary:                  #{convertToRGB(#16a3f5)};
    --primaryDark:              #{convertToRGB(#088fdd)};
    --primaryLight:             #{convertToRGB(#3ab4fa)};

    &.show-mood {
      background-image: linear-gradient(135deg, #f0f1f6 0%,#d5e1f7 100%);

      --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'f7f8fe'}'/%3E%3C/svg%3E%0A");

      --thumbnailBg:              #{convertToRGB(#c1c6d6)};
      --inputBg:                  #{convertToRGB(#f7f9ff)};
      --inputBgHover:             #{convertToRGB(#f0f3fb)};
      --inputBgHover2:            #{convertToRGB(#e9edf9)};
      --borderColor:              #{convertToRGB(#a4adc9)};
      --borderColorFocus:         #{convertToRGB(#c3cbe3)};
      --cardBorderColor:          #{convertToRGB(#a4b6cb)};
      
      --cardBg:                   #{convertToRGB(#f7f9ff)};
      --cardBgHover:              #{convertToRGB(#ecf0f9)};
      --cardBgActive:             #{convertToRGB(#e2e7f1)};

      --tableHeadBg:              #{convertToRGB(#dee6f5)};
      --tableRowBg:               #{convertToRGB(#d5deef)};
      --tableRowHR:               #{convertToRGB(#e3eaf0)};

      --navBg:                    #{convertToRGB(#f7f8fe)};
      --navItemBgHover:           #{convertToRGB(#e9ecf6)};
      --navItemBgActive:          #{convertToRGB(#dadeec)};

      --weekendBg:                #{convertToRGB(#dee6f5)};
      --weekendBgHover:           #{convertToRGB(#d4ddee)};
      
      --faintText:                #{convertToRGB(#4c516a)};
      --toggleBall:               #{convertToRGB(#a2a9cb)};
    }

    &.dark-theme {
      &.show-mood {
        background-image: linear-gradient(135deg, #1e2739 0%,#181d26 100%);

        --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'12161e'}'/%3E%3C/svg%3E%0A");

        --gray1100:                 #{convertToRGB(#212130)};
        --gray1000:                 #{convertToRGB(#272e37)};
        --gray900:                  #{convertToRGB(#2d3444)};
        --gray800:                  #{convertToRGB(#2d333f)};
        --gray750:                  #{convertToRGB(#020202)};
        --gray700:                  #{convertToRGB(#434b64)};
        --gray600:                  #{convertToRGB(#56637c)};
        --gray300:                  #{convertToRGB(#94a9bd)};
        --gray100:                  #{convertToRGB(#f0f4ff)};

        --thumbnailBg:              #{convertToRGB(#434b64)};
        --inputBg:                  #{convertToRGB(#363f4d)};
        --inputBgHover:             #{convertToRGB(#3f4959)};
        --inputBgHover2:            #{convertToRGB(#3a3f4e)};
        --borderColor:              #{convertToRGB(#3d485a)};
        --borderColorFocus:         #{convertToRGB(#2c3541)};
        --cardBorderColor:          #{convertToRGB(#0c111a)};
        --cardBg:                   #{convertToRGB(#2f3743)};
        --cardBgHover:              #{convertToRGB(#374252)};
        --cardBgActive:             #{convertToRGB(#28303c)};

        --tableHeadBg:              #{convertToRGB(#2f3846)};
        --tableRowBg:               #{convertToRGB(#363f4e)};
        --tableRowHR:               #{convertToRGB(#2f3743)};

        --navBg:                    #{convertToRGB(#12161e)};
        --navItemBgActive:          #{convertToRGB(#28303c)};
        --navItemBgHover:           #{convertToRGB(#2f3743)};

        --weekendBg:                #{convertToRGB(#2c3441)};
        --weekendBgHover:           #{convertToRGB(#354050)};
        --faintText:                #{convertToRGB(#b3bed3)};
        --toggleBall:               #{convertToRGB(#888ea1)};
      }
    }
  }
}