.flash-card-widget-component {
  flex-wrap: wrap;
  min-width: 100%;
  width: auto;

  .flash-card {
    text-align: center;
    width: 100%;
    display: flex;
    padding: calc(100% - 2px) 0 0 0;

    .content {
      display: flex;
      left: rem(8px);
      right: rem(8px);
      top: 50%;
      transform: translateY(-50%);
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      position: absolute;
      flex-direction: column;
    }
  }

  .primary-title {
    margin: 0;
  }
}