.topbar-component {
  @include basebar;

  bottom: auto;
  right: 0;
  width: auto;
  overflow-x: auto;
  
  .bar-content {
    padding: 0 rem(16px);
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    height: rem(48px);
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.offset-start {
    left: rem(148px);

    .bar-content {
      width: calc(100vw - 148px);
    }

    @media screen and (max-width: $tabletMax) {
      left: 0;

      .bar-content {
        width: 100vw;
      }
    }
  }

  .brand-wrapper {
    margin: rem(4px) rem(8px) rem(4px) 0;
    font-size: 0;
  }

  .context-menu-wrapper {
    &:not(.align-h-start) {
      direction: rtl;

      button,
      a,
      span,
      div {
        direction: ltr;
      }
    }


    .context-menu {
      direction: ltr;
      position: fixed;
      right: auto;
      top: auto;
      left: auto;
    }
  }
}