.login-component {
    text-align: center;
    display: flex;
    padding: 2rem 0;
    overflow-y: auto;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    .login-container {
      max-width: rem(320px);
      margin: auto;
    }

    .logo {
        display: block;
        width: 100%;
        max-width: 240px;
        max-height: 100px;
        object-fit: contain;
        margin: 0 auto;
    }

    .avatar-strip {
        background-size: auto rem(94px);
        border-radius: rem(48px) rem(48px) rem(48px) rem(8px);
        font-size: rem(94px);
        height: rem(94px);
        margin: 0 auto;
        width: rem(94px);

        &.animate {
            animation: 6.5s ease moveAvatarStrip forwards infinite;
        }
    }

    .disclaimer {
      margin-top: rem(32px);
      font-size: rem(11px);
      color: rgba($gray100, 1);
    }
}

@keyframes moveAvatarStrip {
  0% {
    background-position: 0 0;
  }
  10% {
    background-position: -1em 0;
  }
  20% {
    background-position: -2em 0;
  }
  30% {
    background-position: -3em 0;
  }
  40% {
    background-position: -4em 0;
  }
  50% {
    background-position: -5em 0;
  }
  60% {
    background-position: -6em 0;
  }
  70% {
    background-position: -7em 0;
  }
  80% {
    background-position: -8em 0;
  }
  90% {
    background-position: -9em 0;
  }
  100% {
    background-position: -10em 0;
  }
}