@mixin ghost-button-style {
    border: 0;
    display: inline-flex;
    background: transparent;
    padding: rem(4px) rem(5.35px);
    border-radius: rem(5px);
    color: rgba($gray100, 1);
    transition: .2s ease all;
    font-size: rem(14px);
    vertical-align: top;
    min-width: rem(31px);
    min-height: rem(31px);
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($borderColor, 0);
  
    .app-like & {
      user-select: none;
    }
  
    .text {
      padding: 0 rem(4px);
    }
  
    &.disabled,
    &[disabled],
    a:not([href]) {
      pointer-events: none;
      color: rgba($gray100, .5);
    }
  
    &:hover {
      border: 1px solid rgba($borderColor, .6);
      background: rgba($navItemBgHover, .6);
    }
  
    &:active {
      color: rgba($gray100, .7);
      background: rgba($cardBgHover, .5);
      border: 1px solid rgba($borderColorFocus, 1);
    }

    &.active {
      background: rgba($inputBgHover, .8);
      border: 1px solid rgba($inputBgHover, 1);
    }

    &:focus-visible {
      z-index: 10;
      position: relative;
          box-shadow: $focusOutline;
      }
  
    & + .ghost-button {
      margin-left: rem(4px);
    }
  
    @include button-icon;
  
    //keep the button from moving if the icon appears afterwards (see :has in the future)
    .text-center & {
      &:not(.active) {
          margin-left: rem(4px);
          margin-right: rem(4px);
      }
    }
  }