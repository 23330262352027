.badge-component {
    position: relative;
    display: inline-block;
    .thumbnail {
        transition: .2s $animationEasing transform;
        transform-origin: bottom;
        position: relative;
        z-index: 9;
    }

    &:hover {
        .thumbnail {
            transition: .2s $animationEasing .5s transform;
            transform: scale(5);
            z-index: 10;
        }
    }
}