.breadcrumb-component {
    padding: rem(4px) 3px;

    .primary-title {
        font-size: rem(20px);
        margin: 0;
    }

    .spinner {
        margin-left: 0.1em;
        margin-top: -0.1em;
        width: 0.8em;
        height: 0.8em;
    }
}