.primary-button,
input[type=button],
input[type=submit] {
    background: rgba($primary, 1);
    color: rgba($buttonText, 1);
    border-radius: rem(6px);
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: $primaryType;
    font-size: rem(14px);
    font-weight: 500;
    line-height: rem(24px);
    overflow: visible;
    padding: rem(3.20px) rem(9.40px) rem(3.20px);
    position: relative;
    text-decoration: none;
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
    vertical-align: middle;
    z-index: 9;
    border-bottom: 1px solid rgba($primaryDark, 1);
    white-space: nowrap;

    .app-like & {
      user-select: none;
    }

    .spinner {
      margin-left: 0;
      margin-top: -6px;
      width: 20px;
      height: 20px;
    }

    &.disabled,
    &[disabled],
    a:not([href]) {
      pointer-events: none;
      opacity: .3;
    }

    &:hover {
      background: rgba($primaryLight, 1);
    }

    &:active {
      color: rgba($buttonText, .8);
      background: rgba($primaryDark, 1);
      // transform: scale(.85);
    }

    &:focus-visible {
      box-shadow: $focusOutline;
      z-index: 10;
    }

    .text,
    .dynamic-icon,
    .static-icon {
        vertical-align: middle;
    }

    .static-icon:not(:last-child) {
        margin-right: rem(8px);
    }

    .dynamic-icon {
        display: inline-block;
        font-size: rem(18px);
        line-height: rem(24px);
        margin-right: 0;
        transition: .2s $animationEasing all, 0.01s linear box-shadow;
        width: 0;
        margin-left: rem(-8px);
        margin-right: rem(8px);
        
        &.visible {
            width: rem(24px);
        }
    }
}