.booking-component {
    @include tabular;
    @include calendar;

    table {
        .content {
          width: auto;
          height: auto;
  
          &:focus-within {
            background: transparent;
          }
        }
  
        .spinner {
          margin-left: 0;
          margin-top: -2px;
          width: 20px;
          height: 20px;
        }
  
        .overlap {
          background: solidColor($black);
          border-radius: 500px;
          color: solidColor($white);
          cursor: default;
          display: inline-block;
          font-size: 11px;
          font-weight: bold;
          height: rem(16px);
          line-height: rem(16px);
          position: absolute;
          text-align: center;
          right: rem(1px);
          top: rem(1px);
          width: rem(16px);
          z-index: 4;
          box-shadow: inset 0 0 0 1px solidColor($black), 0 0 0 1px solidColor($black);
  
          & > button {
            background: transparent;
            color: inherit;
            border: 0;
            text-align: center;
            font-weight: 700;
            padding: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            border-radius: 500px;
                    
            &:focus-visible {
              box-shadow: $focusOutline;
            }
          }
  
          &.highlight-medium {
            background: solidColor($dangerMedium);
            color: solidColor($whiteStatic);
            box-shadow: inset 0 0 0 1px solidColor($dangerMedium), 0 0 0 1px solidColor($dangerMedium);
          }
  
          &.highlight-high {
            background: solidColor($dangerHigh);
            color: solidColor($whiteStatic);
            box-shadow: inset 0 0 0 1px solidColor($dangerHigh), 0 0 0 1px solidColor($dangerHigh);
          }
  
          &.highlight-higher {
            background: solidColor($dangerHigher);
            color: solidColor($whiteStatic);
            box-shadow: inset 0 0 0 1px solidColor($dangerHigher), 0 0 0 1px solidColor($dangerHigher);
          }
        }
        
        .option-list {
          position: absolute;
          background: solidColor($cardBg);
          backdrop-filter: none;
          top: calc(100% - 6px);
          left: auto;
          right: rem(14px);
          z-index: 1000;
          width: auto;
          max-height: 30vh;
  
          li {
            button {
              padding: rem(8px);
              flex-shrink: 0;
            }
          }
          
          @each $day, $color in $dayTypes {
            .#{$day} { 
              .icon {
                color: solidColor($color);
              }
            }  
          }
        }
  
        .month-name {
          font-weight: 500;
          white-space: nowrap;

          .month-content {
            padding-right: rem(8px);
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            span,
            small {
              margin: 0 rem(8px);
            }
          }
  
          .overlap {
            position: static;
            margin-left: rem(2px);
          }
        }

        td {
          .ghost-button.calendar-button {
            border-radius: 500px;
            background: rgba($inputBg, .4);
            border: 1px solid rgba($borderColor, .6);
  
            &:not(:hover) {
              color: transparent;         
            }
  
            &:hover {
              border-color: rgba($inputBgHover, 1);
              background: rgba($inputBgHover, .8);
            }
  
            &.active {
              color: solidColor($black);
            }
  
            &[disabled],
            &.disabled {
              pointer-events: none;
              opacity: 1;
            }
  
            &.progress {
              color: inherit;
              position: relative;
  
              .dynamic-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
  
            .tile-text {
              border-radius: 500px;
              width: rem(22px);
              height: rem(22px);
              margin: rem(-3px);
              font-size: rem(11px);
              font-weight: 700;
              color: solidColor($black);
              display: inline-block;
              vertical-align: top;
              line-height: rem(22px);
            }
          }
  
          &.unemployed {
            background-image: linear-gradient(to bottom, transparent 46%,solidColor($gray600) 46%,solidColor($gray600) 56%,transparent 56%,transparent 61%);
  
            .ghost-button.calendar-button,
            .overlap {
              opacity: 0;
              pointer-events: none;

            }
          }

          &.confirmed {
            .content {
              .ghost-button.calendar-button {
                border: 2px solid rgba($positiveColor);
              }
            }
          }
  
          @each $day, $color in $dayTypes {
            &.#{$day} { 
              &::before {
                display: none;
              }
              
              .content {
                &::before {
                  content: "";
                  background: solidColor($color);
                  display: block;
                  right: 0;
                  left: 0;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  height: 4px;
                  z-index: 0;
                  width: auto;
                }

                .option-list {
                  &.context-menu {
                    right: rem(10px);
                  }
                }
                
                .ghost-button.calendar-button {
                  box-shadow: 0 0 0 2px rgba($white, .9), 0 0 0 5px solidColor($color);
                  background: rgba($inputBg, 1);
                  z-index: 1;
                  position: relative;
  
                  &:hover {
                    background: rgba($inputBg, 1);
                  }
                }
              }
              
              &:not(.weekend) {
                .ghost-button.calendar-button {
                  &:not(:hover) {
                    color: solidColor($black);
                  }
                  
                  // &.active {
                  //   color: solidColor($white);
                  // }
                }
              }
              
              &.today {
                &::before {
                  left: rem(2px);
                }
              }
            }
          }
        }
  
        tr:not(.spacer-cell) {

          &.highlight-row {
            box-shadow: 0 0 0 1px rgba($white, .8) inset, 0 0 0 3px rgba($gray100, .3) inset;
            border-radius: rem(8px);
            td {
              padding-top: rem(4px);
              padding-bottom: rem(4px);
              
              &.weekend {
                & + .weekend {
                  &::after {
                    top: rem(4px);
                    bottom: rem(4px);
                  }
                }
              }
            }
          }
  
          &:nth-child(n + 7) {
            .overlap-list {
              bottom: 100%;
              top: auto;
              animation-name: dropup;
            }
  
            .option-list {
              bottom: 100%;
              top: auto;
              animation-name: dropup;
            }
          }
  
          td {
            padding: 0;
  
            .content {
              padding: rem(8px);
            }

            &:nth-child(33n) {
              padding-right: rem(3px);
            }
  
            &.weekend {
              background: transparent;
              position: relative;
  
              .content {
                transition: .2s $animationEasing background-color;
                border-radius: rem(8px);
                background: solidColor($weekendBg);
              }
  
              &::before {
                z-index: 1;
              }
  
              .ghost-button.calendar-button {
                color: rgba($black, .15);
              }
  
              & + .weekend {
  
                .content {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  position: relative;
  
                }
  
                &::after {
                  background-color: solidColor($weekendBg);
                  bottom: 0;
                  content: "";
                  display: block;
                  left: rem(-8px);
                  position: absolute;
                  top: 0;
                  transition: .2s $animationEasing background-color;
                  width: rem(8px);
                  z-index: 0;
                }
              }
            }
  
            &.today {
              position: relative;
              &::before {
                content: "";
                display: block;
                pointer-events: none;
                box-shadow: 0 0 0 1px rgba($white, .8) inset, 0 0 0 2px solidColor($todayColor);
                z-index: 20;
                border-radius: rem(8px);
                top: rem(3px);
                bottom: rem(3px);
                left: rem(1px);
                right: rem(0px);
                position: absolute;
              }
            }
          }
  
          &:hover {
            td {
              .ghost-button.calendar-button {
                background: rgba($inputBg, 1);
              }
  
              &.weekend {
                background: rgba($cardBgHover, .5);
                
                .content {
                  background: rgba($weekendBgHover, 1);
                }
  
                & + .weekend {
                  &::after {
                    background-color: rgba($weekendBgHover, 1);
                  }
                }
              }
            }
          }
        }
      }
}