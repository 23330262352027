.clocking-component {
    @include tabular;
    @include calendar;

    table {
        max-width: rem(137px);
        margin-top: rem(4px);

        &.clocking-tiles {
            max-width: none;
        }

        thead {
        th {
                @each $day, $color in $dayTypes {
                    &.#{$day} {
                        .inner-text {
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                &,
                &:hover {
                    td {
                        &.holiday,
                        &.weekend,
                        &.paid,
                        &.extra,
                        &.sick,
                        &.unpaid,
                        &.unemployed,
                        &.absence {
                            background: transparent;
                        }
                    }
                }
            }
        }

        tr {
            td {
                padding-top: rem(4px);
                
                &:first-child {
                    width: auto;

                    &::before {
                        display: none;
                    }
                }

                @each $day, $color in $dayTypes {
                    &.#{$day} { 
                        position: relative;
                        
                        &::before {
                            display: block;
                            bottom: rem(-6px);
                            top: rem(-6px);
                            margin: 0;
                        }
                    }
                }

                input {
                    padding: rem(4px);
                    border-radius: rem(6px);
                    height: rem(32px);
                    font-size: rem(13px);
                    text-align: center;
                }

                &.holiday,
                &.weekend,
                &.paid,
                &.extra,
                &.sick,
                &.unpaid,
                &.unemployed,
                &.absence {
                    .content {
                        opacity: 0;
                    }
                }

                @include holiday-v-pipe();

                .content {
                    &:not(:focus-within) {
                        .hour-box {
                            background: rgba($cardBgActive, 1);
                            
                            &::placeholder {
                                opacity: 0;
                            }

                            &[disabled],
                            &.disabled {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &:last-child {
                td {
                    padding-bottom: rem(16px);
                }
            }
        }
    }
}