.context-menu {
  border-radius: rem(8px);
  border: 1px solid transparent;
  left: rem(8px);
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: rem(2px);
  position: relative;
  white-space: nowrap;
  max-height: 50vh;

  .context-menu-button {
    @include ghost-button-style;
  }

  hr {
    background: rgba($cardBgHover, 1);
  }

  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"] {
    max-width: none;
  }

  & > li {
    display: none;
    align-items: center;
    position: relative;
    padding: rem(3px);

    & > hr {
      margin: rem(3px) 0;
    }

    &.released {
      min-height: 0;
    }

    .horizontal-spacer {
      flex-basis: 100%;
    }

    .separator {
      margin-bottom: rem(8px);
      display: inline-block;
    }

    .info {
      white-space: normal;
      font-size: rem(12px);
      font-weight: 400;
      min-width: rem(112px);
    }
  }

  &.open {
    background: solidColor($cardBg);
    // background: rgba($cardBg, .6);
    // backdrop-filter: blur(35px);
    border: 1px solid rgba($borderColor, .6);
    z-index: 198;
    box-shadow: 0 rem(2px) rem(12px) rgba($contrastGlow, .7);
    width: calc(100% + 8px);
    animation: .2s ease context-menu forwards;
    transform-origin: top;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: block;
    }
    
    li {
      display: flex;

      & > span,
      & > button,
      & > a {
        &:not(:last-child) {
          margin-right: rem(5px);
        }
      }

      .context-menu-button {
        padding: rem(8px) rem(16px) rem(8px) rem(16px);
        transition: none;
        color: solidColor($gray100);

        &:hover {
          border-color: rgba($inputBgHover, 1);
          background: rgba($inputBgHover, .8);
        }

        &:active {
          border-color: rgba($inputBgHover, 1);
          background: rgba($inputBgHover, .6);
        }

        &:focus-visible {
          box-shadow: $focusOutline;
        }
        
        .icon {
          opacity: 1;
        }

        &.disabled,
        &[disabled] {
          pointer-events: none;
          background: rgba($inputBg, .2);
          opacity: .6;
        }
      }

      
      &.active {
        .context-menu-button {
          border: 1px solid rgba($inputBgHover, 1);
          transition: .2s $animationEasing box-shadow, .2s $animationEasing border, .2s $animationEasing background;
          background: rgba($inputBgHover, .8);
          position: relative;

          &:hover {
            border: 1px solid rgba($borderColor, .5);
            background: rgba($cardBgHover, .7);
          }

          &::before {
            content: "";
            animation: verticalPipeDown .2s ease forwards;
            background-color: solidColor($primary);
            border-radius: rem(8px);
            display: block;
            max-height: rem(16px);
            left: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: rem(4px);
            z-index: 0;
          }
        }
      }
    }

    &.fixed-list {
      li:not(.selection) {
        position: absolute;
        height: auto;
        top: auto;
        bottom: auto;
      }
    }
  }

  .active {

  }

  &:not(.open) {
    .icon {
      opacity: 1;

      &::before {
        content: "\f107";
        font-family: $iconType;
        font-weight: 900;
        font-size: rem(15px);
        color: solidColor($gray100);
        position: relative;
        transform: translateY(1px);
        display: inline-block;
      }
    }
  }
}

.context-menu-wrapper {
  position: relative;

  .context-menu {
    position: absolute;
    left: auto;
    right: 0;
    width: auto;
    margin: 0;
  }

  &.align-h-start {
    .context-menu {
      right: auto;
      left: 0;
    }
  }
}

.context-menu {
  padding: rem(6px);
  margin: rem(0) rem(-7px);

  .context-menu-button {
    z-index: 1;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    font-weight: 500;
    font-size: rem(13px);
  }

  
  .icon {
    margin-right: rem(8px);
    width: 1.1em;
    height: 1.1em;
    text-align: center;
    line-height: 1.1em;
  }

  .text {
    font-size: rem(13px);
    font-weight: 500;
  }

  span {
    vertical-align: middle;
  }

  // button {
  //   color: inherit;
  //   background-color: transparent;
  //   border-radius: rem(7px);
  //   border: 0;
  //   cursor: pointer;
  //   display: block;
  //   font-size: rem(12px);
  //   font-weight: 700;
  //   padding: rem(6px);
  //   position: relative;
  //   text-align: left;
  //   transition: .2s $animationEasing all, 0.01s linear box-shadow;
  //   width: 100%;
  //   z-index: 1;

  //   &:focus-visible {
  //     box-shadow: $focusOutline;
  //     z-index: 2;
  //   }

  //   &::after {
  //     content: "";
  //     background: solidColor($white);
  //     width: rem(8px);
  //     height: rem(8px);
  //     display: block;
  //     border-radius: rem(8px);
  //     position: absolute;
  //     bottom: 0;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     opacity: 0;
  //     transition: none;
  //   }

  // }

  
  .context-menu-selection {
    transition: .2s $animationEasing all, 0.01s linear box-shadow;

    &:hover {
      background-color: solidColor($gray800);
    }

    &::before {
      content: "\f107";
      font-family: $iconType;
      font-weight: 900;
      font-size: rem(15px);
      position: relative;
    }
  }
}