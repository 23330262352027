.CONDUIT {
  &.show-mood {
    background-image: linear-gradient(135deg, #f6f0f3 0%,#f7d5e6 100%);

    --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'fef7fb'}'/%3E%3C/svg%3E%0A");

    --thumbnailBg:              #{convertToRGB(#d6c1cd)};
    --inputBg:                  #{convertToRGB(#fff7fc)};
    --inputBgHover:             #{convertToRGB(#fbf0f6)};
    --inputBgHover2:            #{convertToRGB(#f9e9f2)};
    --borderColor:              #{convertToRGB(#c9a4b8)};
    --borderColorFocus:         #{convertToRGB(#e3c3d3)};
    --cardBorderColor:          #{convertToRGB(#cba4ba)};
    
    --cardBg:                   #{convertToRGB(#fff7fb)};
    --cardBgHover:              #{convertToRGB(#f9ecf3)};
    --cardBgActive:             #{convertToRGB(#f1e2eb)};

    --tableHeadBg:              #{convertToRGB(#f5deec)};
    --tableRowBg:               #{convertToRGB(#efd5e3)};
    --tableRowHR:               #{convertToRGB(#f0e3e9)};

    --navBg:                    #{convertToRGB(#fef7fb)};
    --navItemBgHover:           #{convertToRGB(#f6e9f1)};
    --navItemBgActive:          #{convertToRGB(#ecdae5)};

    --weekendBg:                #{convertToRGB(#f5deec)};
    --weekendBgHover:           #{convertToRGB(#eed4e4)};
    
    --faintText:                #{convertToRGB(#6a4c5e)};
    --toggleBall:               #{convertToRGB(#cba2bb)};
  }

  @media screen {
    background-color: rgba($appBgColor);

    &.dark-theme {
      --white:                    #{convertToRGB(#11111a)};
      --gray1100:                 #{convertToRGB(#212130)};
      --gray1000:                 #{convertToRGB(#272e37)};
      --gray900:                  #{convertToRGB(#2d3444)};
      --gray800:                  #{convertToRGB(#2d333f)};
      --gray750:                  #{convertToRGB(#020202)};
      --gray700:                  #{convertToRGB(#434b64)};
      --gray600:                  #{convertToRGB(#56637c)};
      --gray300:                  #{convertToRGB(#94a9bd)};
      --gray100:                  #{convertToRGB(#f0f4ff)};
      --black:                    #{convertToRGB(#ffffff)};
      
      --appBgColor:               #{convertToRGB(#1b212b)};
      
      --thumbnailBg:              #{convertToRGB(#434b64)};
      --inputBg:                  #{convertToRGB(#2f3743)};
      --inputBgHover:             #{convertToRGB(#3f4959)};
      --inputActiveBg:            #{convertToRGB(#21272e)};
      --inputBgHover2:            #{convertToRGB(#3a424e)};
      --inputUnderline:           #{convertToRGB(#979a9e)};
      
      --borderColor:              #{convertToRGB(#3d485a)};
      --borderColorFocus:         #{convertToRGB(#2c3541)};
      --cardBorderColor:          #{convertToRGB(#0c111a)};
      --cardBg:                   #{convertToRGB(#2f3743)};
      --cardBgHover:              #{convertToRGB(#374252)};
      --cardBgActive:             #{convertToRGB(#28303c)};
  
      --tableHeadBg:              #{convertToRGB(#2f3846)};
      --tableRowBg:               #{convertToRGB(#363f4e)};
      --tableRowHR:               #{convertToRGB(#2f3743)};
  
      --navBg:                    #{convertToRGB(#12161e)};
      --navItemBgActive:          #{convertToRGB(#28303c)};
      --navItemBgHover:           #{convertToRGB(#2f3743)};
  
      --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'12161e'}'/%3E%3C/svg%3E%0A");
      --weekendBg:                #{convertToRGB(#333a44)};
      --weekendBgHover:           #{convertToRGB(#3a424e)};
      --faintText:                #{convertToRGB(#b3bed3)};
      --toggleBall:               #{convertToRGB(#888ea1)};
      
      --boxShadow:                #{convertToRGB(#111111)};
      --contrastGlow:             #{convertToRGB(#000000)};
      
      --lightHoliday:             #{convertToRGB(#f8e1e7)};
      
      --todayColor:               #{convertToRGB(#d2dce9)};
      --danger:                   #{convertToRGB(#ff655a)};
      
      --todayColorContrast:               #{lighten(#111111, 15%)};
      --holidayColorContrast:             #{lighten(#bfc6d4, 15%)};
      --paidColorContrast:                #{lighten(#349bd6, 15%)};
      --absenceColorContrast:             #{lighten(#ff2211, 15%)};
      --unpaidColorContrast:              #{lighten(#bd62b6, 15%)};
      --untrackedColorContrast:           #{lighten(#fad218, 15%)};
      --sickColorContrast:                #{lighten(#c0d64e, 15%)};
      --extraColorContrast:               #{lighten(#21da8d, 15%)};
      --loyaltyColorContrast:             #{lighten(#2cd4e0, 15%)};
        
      &.show-mood {
        background-image: linear-gradient(135deg, #2e1823 0%,#241e20 100%);

        --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'1e121a'}'/%3E%3C/svg%3E%0A");

        --gray1100:                 #{convertToRGB(#30212a)};
        --gray1000:                 #{convertToRGB(#372731)};
        --gray900:                  #{convertToRGB(#442d3d)};
        --gray800:                  #{convertToRGB(#3f2d3a)};
        --gray700:                  #{convertToRGB(#64435b)};
        --gray600:                  #{convertToRGB(#7c566f)};
        --gray300:                  #{convertToRGB(#bd94af)};
        --gray100:                  #{convertToRGB(#fff0fa)};

        --thumbnailBg:              #{convertToRGB(#644357)};
        --inputBg:                  #{convertToRGB(#4d3644)};
        --inputBgHover:             #{convertToRGB(#593f53)};
        --inputBgHover2:            #{convertToRGB(#4b3a4e)};
        --borderColor:              #{convertToRGB(#583d55)};
        --borderColorFocus:         #{convertToRGB(#412c3e)};
        --cardBorderColor:          #{convertToRGB(#1a0c15)};
        --cardBg:                   #{convertToRGB(#432f3b)};
        --cardBgHover:              #{convertToRGB(#52374f)};
        --cardBgActive:             #{convertToRGB(#3c2839)};

        --tableHeadBg:              #{convertToRGB(#462f3e)};
        --tableRowBg:               #{convertToRGB(#4e3646)};
        --tableRowHR:               #{convertToRGB(#432f3b)};

        --navBg:                    #{convertToRGB(#1e121a)};
        --navItemBgActive:          #{convertToRGB(#3c2839)};
        --navItemBgHover:           #{convertToRGB(#432f3e)};

        --weekendBg:                #{convertToRGB(#412c3c)};
        --weekendBgHover:           #{convertToRGB(#503545)};
        --faintText:                #{convertToRGB(#d2c6cc)};
        --toggleBall:               #{convertToRGB(#a18899)};
      }
    }
  }
}