.link-button {
  color: inherit;
  transition: .2s $animationEasing all, 0.01s linear box-shadow;
  border: 0;
  cursor: pointer;
  border-radius: rem(4px);
  background: transparent;
  line-height: normal;
  display: inline;
  padding: 0;
  font-weight: inherit;
  text-transform: inherit;
  position: relative;

  @at-root {
    button#{&} {
      white-space: nowrap;
    }
  }

  .app-like & {
    user-select: none;
  }

  &:focus-visible {   
    box-shadow: $focusOutline;
    z-index: 10;
  }

  &::after {
    transition: .2s $animationEasing all;
    content: "";
    display: block;
    left: 1px;
    right: 2px;
    position: absolute;
    top: 100%;
    height: 1px;
    border-bottom: 1px dashed rgba($black,.5);
  }

  &:hover,
  &:focus {
    &::after {
      border-bottom: 1px dashed rgba($primary, 1);
    }
  }

  &.disabled,
  &[disabled],
  &:not(button):not([type=button]):not([type=submit]):not([href]) {
    pointer-events: none;
    opacity: .6;
  }

  &:active {
    color: rgba($gray100, .7);
    &::after {
      border-bottom: 1px dashed rgba($primary, .8);
    }
  }
}