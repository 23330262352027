.card-list-component {
    list-style-type: none;
    padding: 0;
    flex-wrap: wrap;
    min-width: 100%;
    width: auto;

    .card {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin: 0;

        &.align-top {
            align-items: flex-start;
        }

        &.align-bottom {
            align-items: flex-end;
        }

        &.align-center {
            align-items: center;
        }
                
        .content {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin: 0;
        }
    }

    .primary-title {
        text-transform: none;
        font-size: rem(18px);
    }

    .thumbnail {
        flex-shrink: 0;
        
        & + .text-container {
            margin-left: rem(16px);

            .primary-title {
                margin-bottom: rem(4px);
            }
        }
    }

    .story {
        color: rgba($faintText, 1);
        margin-top: 0;
        font-size: rem(13px);
        line-height: rem(18px);
        letter-spacing: .015em;
        max-width: rem(400px);
        margin-right: rem(8px);

        .primary-title {
            margin: 0 0 rem(4px) 0;
        }
    }
}