@mixin secondary-button-style {
    display: inline-flex;
    justify-content: center;
    background: rgba($cardBg, .7);
    padding: rem(4px) rem(5.35px);
    border-radius: rem(5px);
    color: rgba($gray100, 1);
    transition: .2s ease all;
    font-size: rem(14px);
    vertical-align: top;
    min-width: rem(31px);
    min-height: rem(31px);
    text-align: center;
    cursor: pointer;
    align-items: center;
    border: 1px solid rgba($borderColor, .5);

    .app-like & {
        user-select: none;
    }

    .text {
        line-height: 1;
    }

    &.disabled,
    &[disabled],
    a:not([href]) {
        pointer-events: none;
        color: rgba($gray100, .5);
    }

    &:hover {
        border: 1px solid rgba($borderColor, .5);
        background: rgba($cardBgHover, .7);
    }

    &:active {
        color: rgba($gray100, .7);
        background: rgba($cardBgActive, .5);
        border: 1px solid rgba($borderColorFocus, 1);
    }

    &.active {
      background: rgba($inputBgHover, .8);
      border: 1px solid rgba($inputBgHover, 1);
    }

    &:focus-visible {
        z-index: 10;
        position: relative;
        box-shadow: $focusOutline;
    }

    & + .ghost-button {
        margin-left: rem(4px);
    }
}