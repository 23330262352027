.toolbar-component {
  .form-group {
    margin: 0 rem(8px) 0 0;
    
    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
  }

  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"] {
    min-width: rem(100px);
  }
}