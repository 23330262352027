.main-header {
    align-items: flex-start;
    display: flex;
    height: rem(64px);
    justify-content: space-between;
    left: rem(164px);
    position: fixed;
    right: rem(16px);
    top: rem(8px);
    z-index: 10000;

    .left-side {
        @media screen and (max-width: $tabletMax) {
            overflow-x: auto;
            background: rgba($navBg, 1);
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .right-side {
        min-width: rem(180px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .user-toolbar {
            background: rgba($cardBg, 1);
            border-radius: rem(8px);
            border: 1px solid rgba($borderColor, 1);
            user-select: none;
            padding: rem(4px) rem(8px);
            display: flex;
            align-items: center;
            .dropdown-selection {
                &::before {
                    content: "\f338";
                    font-size: rem(12px);
                    display: inline-block;
                    transform: translateY(2px);
                }
            }
        }
    }

    .thumbnail {
        margin-right: rem(8px);
        display: block;
    }

    .user-info {
        margin-right: rem(8px);
        white-space: nowrap;
        margin-top: rem(2px);
    }
}