.CONDUIT {
  
  &.yellow-accent {
    --primary:                  #{convertToRGB(#ffcc40)};
    --primaryDark:              #{convertToRGB(#f3ba1d)};
    --primaryLight:             #{convertToRGB(#ffd76b)};
    --buttonText:               #{convertToRGB(#111111)};
    
    &.show-mood {
      background-image: linear-gradient(135deg, #f6f5f0 0%,#f7efd5 100%);

      --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'fefdf7'}'/%3E%3C/svg%3E%0A");

      --thumbnailBg:              #{convertToRGB(#d6d0c1)};
      --inputBg:                  #{convertToRGB(#fffdf7)};
      --inputBgHover:             #{convertToRGB(#fbf8f0)};
      --inputBgHover2:            #{convertToRGB(#f9f4e9)};
      --borderColor:              #{convertToRGB(#c9c0a4)};
      --borderColorFocus:         #{convertToRGB(#e3ddc3)};
      --cardBorderColor:          #{convertToRGB(#cbc1a4)};
      
      --cardBg:                   #{convertToRGB(#fffdf7)};
      --cardBgHover:              #{convertToRGB(#f9f6ec)};
      --cardBgActive:             #{convertToRGB(#f1eee2)};

      --tableHeadBg:              #{convertToRGB(#f5f0de)};
      --tableRowBg:               #{convertToRGB(#efe8d5)};
      --tableRowHR:               #{convertToRGB(#f0ece3)};

      --navBg:                    #{convertToRGB(#fefdf7)};
      --navItemBgHover:           #{convertToRGB(#f6f3e9)};
      --navItemBgActive:          #{convertToRGB(#ece9da)};

      --weekendBg:                #{convertToRGB(#f5efde)};
      --weekendBgHover:           #{convertToRGB(#eee9d4)};
      
      --faintText:                #{convertToRGB(#6a644c)};
      --toggleBall:               #{convertToRGB(#cbc0a2)};
    }

    &.dark-theme {
      &.show-mood {
        background-image: linear-gradient(135deg, #28200b 0%,#22201b 100%);

        --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'1e1b12'}'/%3E%3C/svg%3E%0A");

        --gray1100:                 #{convertToRGB(#302b21)};
        --gray1000:                 #{convertToRGB(#373327)};
        --gray900:                  #{convertToRGB(#443a2d)};
        --gray800:                  #{convertToRGB(#3f392d)};
        --gray700:                  #{convertToRGB(#645743)};
        --gray600:                  #{convertToRGB(#7c6f56)};
        --gray300:                  #{convertToRGB(#bdb094)};
        --gray100:                  #{convertToRGB(#fffbf0)};

        --thumbnailBg:              #{convertToRGB(#645743)};
        --inputBg:                  #{convertToRGB(#4d4636)};
        --inputBgHover:             #{convertToRGB(#59513f)};
        --inputBgHover2:            #{convertToRGB(#4e483a)};
        --borderColor:              #{convertToRGB(#524a37)};
        --borderColorFocus:         #{convertToRGB(#413b2c)};
        --cardBorderColor:          #{convertToRGB(#1a170c)};
        --cardBg:                   #{convertToRGB(#433d2f)};
        --cardBgHover:              #{convertToRGB(#524837)};
        --cardBgActive:             #{convertToRGB(#3c3628)};

        --tableHeadBg:              #{convertToRGB(#463f2f)};
        --tableRowBg:               #{convertToRGB(#4e4536)};
        --tableRowHR:               #{convertToRGB(#433d2f)};

        --navBg:                    #{convertToRGB(#1e1b12)};
        --navItemBgActive:          #{convertToRGB(#3c3728)};
        --navItemBgHover:           #{convertToRGB(#433e2f)};
        
        --weekendBg:                #{convertToRGB(#41392c)};
        --weekendBgHover:           #{convertToRGB(#504635)};
        --faintText:                #{convertToRGB(#d7d4cd)};
        --toggleBall:               #{convertToRGB(#a19888)};
      }
    }
  }
}