.CONDUIT {
  &.green-accent {
    --primary:                  #{convertToRGB(#46c351)};
    --primaryDark:              #{convertToRGB(#3baf44)};
    --primaryLight:             #{convertToRGB(#5dda67)};

    &.show-mood {
      background-image: linear-gradient(135deg, #f1f6f0 0%,#d8f7d5 100%);

      --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'f8fef7'}'/%3E%3C/svg%3E%0A");

      --thumbnailBg:              #{convertToRGB(#c1d6c1)};
      --inputBg:                  #{convertToRGB(#f8fff7)};
      --inputBgHover:             #{convertToRGB(#f0fbf0)};
      --inputBgHover2:            #{convertToRGB(#ebf9e9)};
      --borderColor:              #{convertToRGB(#a5c9a4)};
      --borderColorFocus:         #{convertToRGB(#c6e3c3)};
      --cardBorderColor:          #{convertToRGB(#a5cba4)};
      
      --cardBg:                   #{convertToRGB(#f7fff7)};
      --cardBgHover:              #{convertToRGB(#edf9ec)};
      --cardBgActive:             #{convertToRGB(#e3f1e2)};

      --tableHeadBg:              #{convertToRGB(#dff5de)};
      --tableRowBg:               #{convertToRGB(#d8efd5)};
      --tableRowHR:               #{convertToRGB(#e5f0e3)};

      --navBg:                    #{convertToRGB(#f8fef7)};
      --navItemBgHover:           #{convertToRGB(#ebf6e9)};
      --navItemBgActive:          #{convertToRGB(#dcecda)};

      --weekendBg:                #{convertToRGB(#def5de)};
      --weekendBgHover:           #{convertToRGB(#d5eed4)};
      
      --faintText:                #{convertToRGB(#4e6a4c)};
      --toggleBall:               #{convertToRGB(#a5cba2)};
    }

    &.dark-theme {
      &.show-mood {
        background-image: linear-gradient(135deg, #0c281d 0%,#1c221f 100%);

        --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'121e16'}'/%3E%3C/svg%3E%0A");

        --gray1100:                 #{convertToRGB(#213022)};
        --gray1000:                 #{convertToRGB(#283727)};
        --gray900:                  #{convertToRGB(#2e442d)};
        --gray800:                  #{convertToRGB(#303f2d)};
        --gray700:                  #{convertToRGB(#436445)};
        --gray600:                  #{convertToRGB(#5a7c56)};
        --gray300:                  #{convertToRGB(#9cbd94)};
        --gray100:                  #{convertToRGB(#f1fff0)};

        --thumbnailBg:              #{convertToRGB(#436446)};
        --inputBg:                  #{convertToRGB(#364d38)};
        --inputBgHover:             #{convertToRGB(#3f593f)};
        --inputBgHover2:            #{convertToRGB(#3c4e3a)};
        --borderColor:              #{convertToRGB(#3b583c)};
        --borderColorFocus:         #{convertToRGB(#2c412e)};
        --cardBorderColor:          #{convertToRGB(#0c1a0f)};
        --cardBg:                   #{convertToRGB(#2f4334)};
        --cardBgHover:              #{convertToRGB(#37523e)};
        --cardBgActive:             #{convertToRGB(#283c2e)};

        --tableHeadBg:              #{convertToRGB(#2f4633)};
        --tableRowBg:               #{convertToRGB(#364e3b)};
        --tableRowHR:               #{convertToRGB(#2f4334)};

        --navBg:                    #{convertToRGB(#121e16)};
        --navItemBgActive:          #{convertToRGB(#283c2d)};
        --navItemBgHover:           #{convertToRGB(#2f4334)};
        
        --weekendBg:                #{convertToRGB(#2c412e)};
        --weekendBgHover:           #{convertToRGB(#35503a)};
        --faintText:                #{convertToRGB(#c4d3c7)};
        --toggleBall:               #{convertToRGB(#89a188)};
      }
    }
  }
}