.navigation-component {
  width: 100%;

  .nav-toggle {
    display: none;
  }

  .nav-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    flex-basis: 100%;

    .nav-li {
      margin: rem(3px) 0;
      padding: 0 rem(8px);
      flex-shrink: 0;
      display: flex;
      align-items: center;

      &:last-child {
        padding-bottom: rem(8px);
      }

      .link-text {
        span {
          flex-shrink: 0;
        }
      }
    }
  }

  .nav-link {
    @include ghost-button-style;
    position: relative;
    padding: 0;
    width: 100%;
    text-align: left;
    justify-content: flex-start;

    .icon,
    .icon-contain-h,
    .icon-contain-v {
      height: rem(24px);
      font-size: rem(18px);
      margin-right: rem(8px);
      object-fit: contain;
      object-position: center center;
      width: rem(24px);
      text-align: center;
      line-height: rem(24px);

      &:last-child {
        margin-right: 0;
      }
    }

    .icon-contain-h {
      height: auto;
    }

    .icon-contain-v {
      width: auto;
    }

    .content-wrapper {
      align-items: center;
      color: inherit;
      display: flex;
      flex-wrap: nowrap;
      font-size: rem(12.5px);
      font-weight: 400;
      margin-top: rem(2px);
      padding: rem(16px) rem(8px);
      transition: .2s $animationEasing all, 0.01s linear box-shadow;
      z-index: 1;

      &.center-h {
        justify-content: center;
      }

      &::before {
        content: "";
        animation: verticalPipeDown .2s ease forwards;
        background-color: rgba($primary, 1);
        border-radius: rem(8px);
        display: none;
        height: rem(32px);
        left: rem(-2px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: rem(4px);
        z-index: 0;
      }
    }

    &.active {
      border: 1px solid rgba($borderColor, .6);
      background: rgba($navItemBgHover, .6);
      pointer-events: none;
      user-select: none;

      .content-wrapper {
        &::before {
          display: block;
        }
      }
    }

    &:active {
      background: rgba($navItemBgActive, .5);
    }
  }

  &.horizontal {
    bottom: auto;
    right: 0;
    width: auto;
    overflow: visible;

    .nav-list {
      height: auto;
      flex-direction: row;
    }

    .nav-context {
      width: auto;
      margin: 0;
      max-height: none;

      .context-menu {
        margin: 0;
        margin-right: rem(8px);
        padding: rem(2px);
      }

      button {
        padding: rem(10px) rem(16px);
      }
    }

    .nav-li {
      padding: rem(4px) rem(3px) rem(5px) rem(3px);
      margin: 0;

      &:last-child {
        padding-bottom: rem(5px);
      }
    }

    .nav-link {
      .content-wrapper {
        padding: rem(8px) rem(12px);
        margin-top: 0;

        &.squared {
          padding: rem(8px);
        }
      }

      &.active {
        .content-wrapper {          
          &::before {
            animation: horizontalPipeRight .2s ease forwards;
            background-color: rgba($primary, 1);
            border-radius: rem(8px);
            width: rem(32px);
            top: auto;
            bottom: rem(-2px);
            left: 50%;
            transform: translateX(-50%);
            height: rem(4px);
          }
        }
      }
    }
  }

  .nav-context {
    width: 100%;

    .nav-link {
      .content-wrapper {
        padding: rem(4px) rem(4px) rem(4px) rem(12px);
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 500;
        font-size: 13px;
      }

      & + .context-menu {
        min-width: 100%;
      }

      &.active {
        .content-wrapper {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}