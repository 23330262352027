.card-flyout-component {
  position: fixed;
  top: rem(56px);
  right: rem(8px);
  max-width: calc(50vw - 16px);
  height: calc(100vh - 64px);
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-3.2rem);

  &.show-flyout {
    animation: .2s revealLeft .2s forwards;
  }

  &.hide-flyout {
    animation: .2s hideRight forwards !important;
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
  }

  .flyout-content {
    margin-top: rem(8px);
    overflow: auto;
    max-height: calc(100vh - 148px);
  }
}

.flyout-sibling {
  max-width: 100%;
  transition: .2s $animationEasing max-width;
}