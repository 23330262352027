.CONDUIT,
#root {
    font-family: $primaryType;
    font-weight: 400;
    color: rgba($gray100, 1);

    @media print{
        color: rgba($blackStatic, 1);
    }
}

.no-wrap {
  white-space: nowrap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  .icon {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    margin: 0;
    line-height: 1.3em;
  }

  span {
    vertical-align: middle;
  }
}

h1,
.h1 {
  &.primary-title {
    font-size: rem(24px);
  }
}

h2,
.h2 {
  &.primary-title {
    font-size: rem(20px);
  }
}

h3,
.h3 {
  &.primary-title {
    font-size: rem(18px);
  }
}

h4,
.h4 {
  &.primary-title {
    font-size: rem(16px);
  }
}

h5,
.h5 {
  &.primary-title {
    font-size: rem(14px);
  }
}

h6,
.h6 {
  &.primary-title {
    font-size: rem(12px);
  }
}

.text-chunk {
  max-width: rem(640px);
  font-weight: normal;
  letter-spacing: normal;
  white-space: pre-line;

  &.fill {
    max-width: none;
  }
}

code {
  color: rgba($primary, 1);
  background: rgba($appBgColor, 1);
  border: 1px solid rgba($borderColor, 1);
  padding: rem(4px) rem(8px);
  border-radius: rem(5px);
}

a {
    color: inherit;
    text-decoration: none;
}

p {
  font-size: rem(15px);
  margin-top: 0;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.faint-text {
  color: rgba($faintText, 1);
}

.default-text {
  color: rgba($gray100, 1);
}

.accent-text {
  color: rgba($primary, 1);
  @each $accent, $color in $accentTypes {
    &-#{$accent} { 
      color: rgba($color, 1);
    }
  }
}

.positive-text {
  color: rgba($positiveColor, 1);
}

.negative-text {
  color: rgba($negativeColor, 1);
}

.flag-text {
  border: 1px solid rgba($primary, 1);
  padding: 0 rem(4px) rem(1px);
  border-radius: rem(5px);
  background: transparent;
  display: inline-block;
  @each $accent, $color in $accentTypes {
    &-#{$accent} { 
      border: 1px solid rgba($color, 1);
      padding: 0 rem(4px) rem(1px);
      border-radius: rem(5px);
      background: transparent;
      display: inline-block;
    }
  }
}

@each $accent, $color in $accentTypes {
  .badge-text {
    &.accent-#{$accent} { 
      background-color: rgba($color, 1);
      font-size: rem(13px);
      font-weight: 500;
      color: rgba($white, 1);
      padding: 0 rem(4px) rem(1px);
      border-radius: rem(4px);
      display: inline-block;
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

small {
  padding-bottom: 0.3em;
  display: inline-block;
}

.primary-title {
    margin-bottom: rem(0);
    font-weight: 300;
    font-size: rem(24px);
    color: rgba($gray100, 1);
    display: inline-block;
    text-transform: lowercase;
    line-height: normal;

    &::first-letter {
      font-weight: 500;
    }
  
    a {
      color: rgba($gray100, 1);
    }

    .fas,
    .far,
    .fab {
      margin-left: rem(5px);
      margin-right: rem(5px);
      vertical-align: middle;
    }

    small {
      font-size: .6em;
    }

    .dynamic-icon {
      .fas,
      .far,
      .fab {
        font-size: .7em;
      }
    }



    strong {
      font-weight: 500;
    }

    &:last-child:not(:first-child) {
      margin-bottom: 0;
    }
}

.page-title {
  position: sticky;
  top: 0;
  margin: 0;
}

.break-all {
  word-break: break-all;
}

.image-link {
  img {
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
  }

  &.active,
  &:focus-visible {
    img {
      box-shadow: $focusOutline;
      z-index: 10;
    }
  }
}

strong {
  font-weight: 700;
}

// .sticky-container {
// 	position: relative;

// 	.sticky-title {
// 		position: sticky;
// 		display: block;
// 		top: 0;
// 		z-index: 100;
// 		background: rgba($appBgColor, 1);
// 		padding-bottom: rem(4px);
//     margin-bottom: 0;

//     & + .card {
//       margin-top: rem(4px);
//     }
// 	}
// }

ul {
	margin: 0;
	padding-left: rem(16px);

	&.unstyled {
		list-style-type: none;
		padding-left: 0;
	}
}

hr {
	width: 100%;
	background: rgba($cardBg, 1);
	border: 0;
	height: 1px;
}


.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.normalcase {
  text-transform: none;
}