@mixin conduit-input {
    background: rgba($inputBg, .5);
    border-radius: rem(6px);
    border: 1px solid rgba($borderColor, .6);
    filter: none;
    color: rgba($gray100, 1);
    font-size: rem(14px);
    height: rem(32px);
    max-width: rem(296px);
    padding: rem(1px) rem(16px) rem(1px) rem(16px);
    border-bottom: 1px solid rgba($inputUnderline, 1);
    width: 100%;
  
    &:hover {
      background: rgba($inputBgHover, .5);
    }
  
    &:focus {
      background: rgba($white, 1);
      outline: 0;
      color: rgba($gray100, 1);
      padding: rem(1px) rem(16px) 0 rem(16px);
      border-color: rgba($borderColorFocus, 1);
      border-bottom: 2px solid rgba($primary, 1);
    }
  
    &.mw-small {
      max-width: rem(148px);
    }
  
    &.fill {
      max-width: none;
    }
  
    &.invalid {
      border-color: rgba($danger, 1);
    }
  
    &[disabled],
    &.disabled {
        resize: none;
        pointer-events: none;
        background: rgba($inputBg, .4);
        border-color: rgba($borderColor, .6);
        opacity: .8;
    }
  
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px rgba($inputBg, 1) inset !important;
        -webkit-text-fill-color: rgba($gray100, 1) !important;
      
      }
      
      &:-webkit-autofill:hover {
        -webkit-box-shadow: 0 0 0px 1000px rgba($inputBgHover, 1) inset !important;
        -webkit-text-fill-color: rgba($gray100, 1) !important;
      }
      
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px rgba($white, 1) inset !important;
        -webkit-text-fill-color: rgba($gray100, 1) !important;
        border-color: rgba($borderColorFocus, 1);
        border-bottom: 2px solid rgba($primary, 1);
      }
      
      &[aria-invalid=true] {
        border-bottom-color: rgba($danger, 1);
      }
  }