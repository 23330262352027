.radio-group {
  display: flex;
  flex-wrap: wrap;

  .form-group {
    margin-right: rem(16px);

    &:last-child {
      margin-right: 0;
    }
  }
}

input[type=radio] {
  height: rem(20px);
  width: rem(20px);
  margin-right: rem(5px);
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba($borderColor, 1) inset;
  background: transparent;
  transition: all .2s linear;

  & + label {
    padding-bottom: 0;
  }

  &:hover {
      background: rgba($gray1000, 1);

      &:checked {
        box-shadow: 0 0 0 4px rgba($primary, 1) inset;
      }

      &:active {
        box-shadow: 0 0 0 5px rgba($borderColor, 1) inset;
        background-color: rgba($blackStatic, 1);
      }
  }

  &:active {
    &:checked {
      box-shadow: 0 0 0 5px rgba($primary, 1) inset;
    }
  }

  &:checked {
    background-color: rgba($blackStatic, 1);
    box-shadow: 0 0 0 5px rgba($primary, 1) inset;
  }

  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: .3;

    + label {
      pointer-events: none;
      opacity: .3;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 1px rgba($white, 1),
                0 0 0 3px rgba($gray100, 1),
                0 0 0 5px rgba($primary, 1) inset;
    z-index: 10;
  }
}