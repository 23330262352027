select {
  @include conduit-input;
}

select[multiple] {
    height: auto;
    padding-bottom: 0;
    padding-top: rem(16px);
  
    option {
      border-radius: rem(4px);
      margin: rem(1px) 0;
      transition: .2s $animationEasing all, 0.01s linear box-shadow;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
      vertical-align: middle;
      padding-top: rem(4px);
      // box-shadow: 0 0 10px 100px rgba($inputBg, 1) inset; //expriment 
  
      &:hover {
        // box-shadow: 0 0 10px 100px rgba($gray600, 1) inset; //expriment 
      }
  
      &:checked,
      &:focus {
        // box-shadow: 0 0 10px 100px rgba($primary, 1) inset !important; //expriment 
        // color: rgba($buttonText, 1) !important;
      }
    }
  
    &:hover {
      option {
      //  box-shadow: 0 0 10px 100px rgba($inputBgHover, 1) inset; //expriment 
      }
    }
  
    &:focus {
      margin-bottom: -1px;
      
      option {
        // box-shadow: 0 0 10px 100px rgba($inputBg, 1) inset; //expriment 
      }
    }
    
    &.disabled {
      option {
        box-shadow: none !important;
      }
    }
  
    &.horizontal {
      width: auto;
      padding: rem(4px);
      max-width: none;
      white-space: normal;
      height: rem(32px);
      overflow-y: hidden;
      
      option {
        height: 100%;
        width: rem(64px);
        display: inline-block;
        margin: 0 rem(1px);
      }
  
      &.fill {
        width: 100%;
      }
    }
  }