.holiday-widget-component {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      font-size: rem(16px);
      font-weight: normal;
      padding: rem(16px);
      letter-spacing: -0.025em;
      border-radius: rem(8px);

      small {
        color: solid($faintText);
        font-size: rem(14px);
      }

      &.next,
      &.current {
        color: rgba($gray100, 1);
        position: relative;

        &::before {
          content: "";
          animation: verticalPipeDown .2s ease forwards;
          background-color: rgba($primary, 1);
          border-radius: rem(8px);
          display: block;
          height: rem(32px);
          left: rem(-2px);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: rem(4px);
          z-index: 0;
        }
      }

      &.current {
        background: rgba($inputBgHover, 1);
        margin-bottom: rem(8px);
      }
    }
}
  