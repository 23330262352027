.control-list-component {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  &.unwrappable {
    flex-wrap: nowrap;
  }
  
  & > li {
    margin-top: rem(3px);
    margin-bottom: rem(3px);
    // display: flex;

    &.unwrappable {
      flex-wrap: nowrap;
    }

    &:not(:last-child) {
      margin-right: rem(5px);
    }

    .badge-text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      display: block;
    }
  }

  &.align-h-end {
    justify-content: flex-end;

    & > li {
      margin-right: 0;
      
      &:not(:first-child) {
        margin-left: rem(5px);
      }
      
    }
  }
}