fieldset {
  border-radius: rem(6px);
  border: 1px solid rgba($borderColor, .6);
  padding: rem(16px);
  padding-bottom: rem(8px);

  legend {
    font-size: rem(14px);
    color: rgba($faintText, 1);
    font-weight: 300;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: rgba($cardBg, .9);
    backdrop-filter: blur(15px);
    padding: 3px;
    border-radius: 3px;
  }
}

label {
    font-weight: 300;
    font-size: rem(13px);
    padding-bottom: rem(4px);
    display: inline-block;
    color: rgba($gray100, 1);
}

.form-group {
  margin-bottom: rem(16px);
  text-align: left;

  &:empty {
    display: none;
  }
  
  &.tight {
    margin-bottom: rem(3px);
  }
}

.horizontal-flow {
  .form-group {
    &:not(:last-child) {
      margin-right: rem(8px);
    }
  }

  &-mirrored {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    .form-group {
      &:not(:first-child) {
        margin-left: rem(8px);
      }
    }
  }
}

