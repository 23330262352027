.timetrack-component {
  @include tabular;
  @include calendar;

  table {
    tbody {
      tr {
        &:first-child {
          td {
            @each $day, $color in $dayTypes {
              &.#{$day} {
                &::after {
                  content: "";
                  position: absolute;
                  top: calc(100% + 8px);
                  left: 50%;
                  transform: translateX(-50%);
                  width: rem(4px);
                  height: calc(100vh - 290px);
                  border: 2px dashed solidColor($color);
                }
              }
            }
          }
        }
      }
    }

    td {
      .content {
        width: rem(34px);
        height: rem(48px);

        &:focus-within {
          z-index: 20;
          background-color: solidColor($primary);
          border-radius: rem(9px);
        }
      }

      @include holiday-v-pipe();
    }
  }
}