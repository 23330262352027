/* Statics - not affected by theme */
$blackStatic:               var(--blackStatic);
$whiteStatic:               var(--whiteStatic);

$gray1100Static:            var(--gray1100Static);

/* Main Colors */
$white:                     var(--white);
$gray1100:                  var(--gray1100);
$gray1000:                  var(--gray1000);
$gray900:                   var(--gray900);
$gray800:                   var(--gray800);
$gray700:                   var(--gray700);
$gray750:                   var(--gray750);
$gray600:                   var(--gray600);
$gray300:                   var(--gray300);
$gray100:                   var(--gray100);
$black:                     var(--black);

$primary:                   var(--primary);
$primaryDark:               var(--primaryDark);
$primaryLight:              var(--primaryLight);

$danger:                    var(--danger);
$dangerMedium:              var(--dangerMedium);
$dangerHigh:                var(--dangerHigh);
$dangerHigher:              var(--dangerHigher);

$appBgColor:                var(--appBgColor);

$navBg:                     var(--navBg);
$navItemBgActive:           var(--navItemBgActive);
$navItemBgHover:            var(--navItemBgHover);

$borderColor:               var(--borderColor);
$borderColorFocus:          var(--borderColorFocus);

$tableHeadBg:               var(--tableHeadBg);
$tableRowBg:                var(--tableRowBg);
$tableRowHR:                var(--tableRowHR);

$thumbnailBg:               var(--thumbnailBg);
$cardBorderColor:           var(--cardBorderColor);
$cardBg:                    var(--cardBg);
$cardBgHover:               var(--cardBgHover);
$cardBgActive:              var(--cardBgActive);
$toggleBall:                var(--toggleBall);

$inputUnderline:            var(--inputUnderline);
$inputBg:                   var(--inputBg);
$inputActiveBg:             var(--inputActiveBg);
$inputBgHover:              var(--inputBgHover);
$inputBgHover2:             var(--inputBgHover2);
$weekendBg:                 var(--weekendBg);
$weekendBgHover:            var(--weekendBgHover);
$buttonText:                var(--buttonText);
$boxShadow:                 var(--boxShadow);
$contrastGlow:              var(--contrastGlow);

$lightHoliday:              var(--lightHoliday);
$faintText:                 var(--faintText);

$todayColor:                var(--todayColor);
$holidayColor:              var(--holidayColor);
$paidColor:                 var(--paidColor);
$absenceColor:              var(--absenceColor);
$unpaidColor:               var(--unpaidColor);
$untrackedColor:            var(--untrackedColor);
$sickColor:                 var(--sickColor);
$extraColor:                var(--extraColor);
$loyaltyColor:              var(--loyaltyColor);
$positiveColor:             var(--positiveColor);
$negativeColor:             var(--negativeColor);

$todayColorContrast:                var(--todayColorContrast);
$holidayColorContrast:              var(--holidayColorContrast);
$paidColorContrast:                 var(--paidColorContrast);
$absenceColorContrast:              var(--absenceColorContrast);
$unpaidColorContrast:               var(--unpaidColorContrast);
$untrackedColorContrast:            var(--untrackedColorContrast);
$sickColorContrast:                 var(--sickColorContrast);
$extraColorContrast:                var(--extraColorContrast);
$loyaltyColorContrast:              var(--loyaltyColorContrast);


$pinkAccent:                var(--pinkAccent);
$cyanAccent:                var(--cyanAccent);
$blueAccent:                var(--blueAccent);
$yellowAccent:              var(--yellowAccent);
$orangeAccent:              var(--orangeAccent);
$greenAccent:               var(--greenAccent);
$purpleAccent:              var(--purpleAccent);
$redAccent:                 var(--redAccent);

$animationEasing: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$focusOutline: 0 0 0 1px rgba($white, 1), 0 0 0 3px rgba($gray100, 1);
$focusOutlineNav: 0 0 0 3px rgba($navBg, 1), 0 0 0 5px rgba($whiteStatic, 1);

/* Main Fonts */
$primaryType: 'Ubuntu', sans-serif;
$iconType: "Font Awesome 5 Pro";

$doubleNavCorner:    var(--doubleNavCorner);

$mobileMax: 767px;
$tabletMin: 768px;
$tabletMax: 1100px;
$desktopMin: 1101px;

$transitionRightOffset: rem(16px);

$dayTypes: (
    "holiday": $holidayColor,
    "paid": $paidColor,
    "leave": $absenceColor,
    "absence": $absenceColor,
    "unpaid": $unpaidColor,
    "untracked": $untrackedColor,
    "sick": $sickColor,
    "extra": $extraColor,
    "loyalty": $loyaltyColor,
    "remotePlanned": $extraColor,
    "remote": $absenceColor,
    "totalTickets": $gray600,
    "totalHours": $gray600,
);

$accentTypes: (
    "cyan": $cyanAccent,
    "blue": $blueAccent,
    "orange": $orangeAccent,
    "yellow": $yellowAccent,
    "green": $greenAccent,
    "purple": $purpleAccent,
    "pink": $pinkAccent,
    "red": $redAccent,
);

$dayTypesContrast: (
    "holiday": $holidayColorContrast,
    "paid": $paidColorContrast,
    "absence": $absenceColorContrast,
    "unpaid": $unpaidColorContrast,
    "untracked": $untrackedColorContrast,
    "sick": $sickColorContrast,
    "extra": $extraColorContrast,
    "loyalty": $loyaltyColorContrast,
    "totalTickets": $gray300,
    "totalHours": $gray300,
);

$themeBase: 'light';

.CONDUIT {
    --blackStatic:              #{convertToRGB(#000000)};
    --whiteStatic:              #{convertToRGB(#ffffff)};
    --gray1100Static:           #{convertToRGB(#f9f9f9)};
    --white:                    #{convertToRGB(#ffffff)};

    --gray1100:                 #{convertToRGB(#f9f9f9)};
    --gray1000:                 #{convertToRGB(#f6f6f6)};
    --gray900:                  #{convertToRGB(#f2f2f2)};
    --gray800:                  #{convertToRGB(#ebebeb)};
    --gray750:                  #{convertToRGB(#e0e0e0)};
    --gray700:                  #{convertToRGB(#cbcbcb)};
    --gray600:                  #{convertToRGB(#bbbbbb)};
    --gray300:                  #{convertToRGB(#444444)};
    --gray100:                  #{convertToRGB(#111111)};
    --black:                    #{convertToRGB(#000000)};

    --primary:                  #{convertToRGB(#de3766)};
    --primaryDark:              #{convertToRGB(#c32250)};
    --primaryLight:             #{convertToRGB(#ec537e)};

    --danger:                   #{convertToRGB(#f5613e)};
    --dangerMedium:             #{convertToRGB(#ee8300)};
    --dangerHigh:               #{convertToRGB(#e55a19)};
    --dangerHigher:             #{convertToRGB(#d60000)};


    --purpleAccent:             #{convertToRGB(#9242d3)};
    --blueAccent:               #{convertToRGB(#16a3f5)};
    --cyanAccent:               #{convertToRGB(#16f1f5)};
    --greenAccent:              #{convertToRGB(#46c351)};
    --yellowAccent:             #{convertToRGB(#ffcc40)};
    --orangeAccent:             #{convertToRGB(#fe6e0d)};
    --redAccent:                #{convertToRGB(#ea1919)};
    --pinkAccent:               #{convertToRGB(#de3766)};
    
    // --appBgColorAccent:              #{convertToRGB(#eff4f9)};
    --thumbnailBg:              #{convertToRGB(#cbcbcb)};
    --toggleBall:               #{convertToRGB(#cbcbcb)};
    --appBgColor:               #{convertToRGB(#e9edf0)};
    
    --navBg:                    #{convertToRGB(#dfe6ea)};
    --navItemBgActive:          #{convertToRGB(#dee2e6)};
    --navItemBgHover:           #{convertToRGB(#f0f3f6)};

    --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'dfe6ea'}'/%3E%3C/svg%3E%0A");
    --borderColor:              #{convertToRGB(#c5c9cd)};
    --borderColorFocus:         #{convertToRGB(#c5c9cd)};
    --cardBorderColor:          #{convertToRGB(#c5c9cd)};

    --cardBg:                   #{convertToRGB(#ffffff)};
    --cardBgHover:              #{convertToRGB(#f0f3f6)};
    --cardBgActive:             #{convertToRGB(#ebeff1)};

    --tableHeadBg:              #{convertToRGB(#e9f1f6)};
    --tableRowBg:               #{convertToRGB(#dee7ee)};
    --tableRowHR:               #{convertToRGB(#e3eaf0)};

    --inputBg:                  #{convertToRGB(#fafcfd)};
    --inputActiveBg:            #{convertToRGB(#d8dce0)};
    --inputBgHover:             #{convertToRGB(#ebeff1)};
    --inputBgHover2:            #{convertToRGB(#e3e6e9)};
    --weekendBg:                #{convertToRGB(#e8ebee)};
    --weekendBgHover:           #{convertToRGB(#e3e6e9)};
    --inputUnderline:           #{convertToRGB(#84878a)};
    --buttonText:               #{convertToRGB(#ffffff)};
    --boxShadow:                #{convertToRGB(#444444)};
    --contrastGlow:             #{convertToRGB(#999999)};

    --lightHoliday:             #{convertToRGB(#e5e9f1)};
    --faintText:                #{convertToRGB(#555463)};

    --todayColor:               #{convertToRGB(#111111)};
    --holidayColor:             #{convertToRGB(#bfc6d4)};
    --paidColor:                #{convertToRGB(#349bd6)};
    --absenceColor:             #{convertToRGB(#ff2211)};
    --unpaidColor:              #{convertToRGB(#bd62b6)};
    --untrackedColor:           #{convertToRGB(#fad218)};
    --sickColor:                #{convertToRGB(#c0d64e)};
    --extraColor:               #{convertToRGB(#21da8d)};
    --loyaltyColor:             #{convertToRGB(#2cd4e0)};
    --positiveColor:            #{convertToRGB(#00ff00)};
    --negativeColor:            #{convertToRGB(#ff0000)};

    --todayColorContrast:               #{darken(#111111, 15%)};
    --holidayColorContrast:             #{darken(#bfc6d4, 15%)};
    --paidColorContrast:                #{darken(#349bd6, 15%)};
    --absenceColorContrast:             #{darken(#ff2211, 15%)};
    --unpaidColorContrast:              #{darken(#bd62b6, 15%)};
    --untrackedColorContrast:           #{darken(#fad218, 15%)};
    --sickColorContrast:                #{darken(#c0d64e, 15%)};
    --extraColorContrast:               #{darken(#21da8d, 15%)};
    --loyaltyColorContrast:             #{darken(#2cd4e0, 15%)};

    @media print {
        --tableHeadBg:                  #{convertToRGB(#e9f1f6)} !important;
    }      
}