.dropdown {
  position: relative;
  transition: .2s ease all;

  &-toggle {
    vertical-align: -moz-middle-with-baseline;
    background-color: transparent;
    color: rgba($gray100, 1);
    border: none;
    width: 100%;
    text-align: left;
    position: relative;
    background: rgba($inputBg, .5);
    border-radius: rem(6px);
    border: 1px solid rgba($borderColor, .6);
    filter: none;
    color: rgba($gray100, 1);
    font-size: rem(14px);
    height: rem(32px);
    max-width: rem(296px);
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
    padding: rem(1px) rem(16px) rem(1px) rem(16px);
    width: 100%;
    z-index: 101;

    &:hover {
      background: rgba($inputBgHover, .5);
    }
    
    &:active {
      background: rgba($cardBgHover, .5);
      border: 1px solid rgba($borderColorFocus, 1);
    }

    &:focus-visible {
      box-shadow: $focusOutline;
    }

    &.mw-small {
      max-width: rem(148px);
    }

    &.fill {
      max-width: none;
    }

    &.invalid {
      border-color: rgba($danger, 1);
    }

    &[disabled],
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: rem(6px);
      transform: translateY(-50%) rotate(45deg);
      width: rem(6px);
      height: rem(6px);
      border-bottom: rem(2px) solid rgba($gray100, 1);
      border-right: rem(2px) solid rgba($gray100, 1);
    }
  }

  &-options {
    visibility: hidden;
    position: fixed;
    background: rgba($cardBg, 1);
    // background: rgba($cardBg, .6);
    // backdrop-filter: blur(35px);
    border: 1px solid rgba($borderColor, .6);
    box-shadow: 0 rem(4px) rem(8px) rgba($contrastGlow, .5);
    z-index: 102;
    list-style: none;
    overflow-y: auto;
    max-height: calc(50vh - 8px);
    max-width: rem(296px);
    padding: rem(0) rem(6px) rem(0) rem(6px);
    border-radius: rem(6px);
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
    transform: translateY(-32px);
    opacity: 0;
    scrollbar-color: rgba($gray100,.3) rgba($gray700, .3); 
	  scrollbar-width: thin;
    z-index: 1000000;

    &::-webkit-scrollbar {
      width: 3px;
      &:hover {
        width: 6px;
      }
    }
    
    &::-webkit-scrollbar-track {
      transition: .2s ease all;
      background: rgba($gray700, .3);
      border-radius: 20px;
    }
    
    &::-webkit-scrollbar-thumb {
      transition: .2s ease all;
      background-color: rgba($gray100,.3);
      border-radius: 20px;
      border: transparent;
    }

    &.open {
      visibility: visible;
      padding: rem(4px) rem(6px) rem(4px) rem(6px);
      opacity: 1;
      max-height: 50vh;
    }

    li {
      padding: rem(8px) rem(15px);
      margin: rem(2px) 0;
      border-radius: rem(6px);
      position: relative;
      font-size: rem(14px);
      cursor: default;
      user-select: none;

      > span {
        white-space: nowrap;
      }

      &.selected,
      &:hover:not(.disabled) {
        background: rgba($inputBgHover, 1);
      }

      &.selected {
        &::before {
          content: "";
          animation: verticalPipeDown .2s ease forwards;
          width: rem(4px);
          max-height: rem(16px);
          border-radius: rem(2px);
          background: rgba($primary, 1);
          position: absolute;
          left: rem(4px);
          top: 50%;
          transform: translateY(-50%);
        }

        &:focus-visible {
          box-shadow: $focusOutline;
        }
      }

      &.disabled {
        pointer-events: none;
        color: rgba($gray600, 1);
      }
    }
  }
}

.dropdown-multiple {
  list-style: none;
  background: rgba($white, 1);
  z-index: 102;
  list-style: none;
  overflow-y: auto;
  max-height: rem(300px);
  max-width: rem(296px);
  padding: rem(4px) rem(6px) rem(4px) rem(6px);
  border-radius: rem(6px);
  border: 1px solid rgba($borderColor, 1);

  > li {
    padding: rem(8px) rem(15px);
    margin: rem(2px) 0;
    border-radius: rem(6px);
    position: relative;
    font-size: rem(12px);
    user-select: none;

    > span {
      white-space: nowrap;
    }

    &.selected,
    &:hover:not(.disabled) {
      background: rgba($inputBgHover, 1);
    }

    &.selected {
      &::before {
        content: "";
        width: rem(2px);
        height: rem(14px);
        border-left: rem(2px) solid rgba($primary, 1);
        border-right: rem(2px) solid rgba($primary, 1);
        border-radius: rem(2px);
        position: absolute;
        left: rem(4px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}