[contentEditable] {
    border: 1px solid rgba($borderColor, .6);
    border-bottom: 1px solid rgba($inputUnderline, 1);
    border-radius: rem(6px);
    background: rgba($inputBg, .5);
    padding: rem(4px);
    display: inline-block;
    margin-bottom: rem(1px);
    margin-top: rem(1px);
  
    &:hover {
      background: rgba($inputBgHover, .5);
    }
  
    &:focus {
      background: rgba($white, 1);
      outline: 0;
      color: rgba($gray100, 1);
      border-color: rgba($borderColorFocus, 1);
      border-bottom: 2px solid rgba($primary, 1);
      padding-bottom: rem(3px);
      z-index: 100;
      position: relative;
    }
  }
  