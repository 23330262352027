.tableview-component {
  @include tabular;
  
  tr {
    td {
      border-top: 1px solid rgba($tableRowHR, 1);
    }

    &:first-child {
      td {
        border-top: 1px solid rgba($tableRowHR, 0);
      }
    }

    td {
      &::after {
        content: "";
        display: block;
        right: 0;
        height: 1px;
        background: rgba($cardBg, 0);
        transition: .2s $animationEasing background-color;
        left: 0;
        position: absolute;
        z-index: 5;
        top: -1px;
      }

      table {
        tr {
          td {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    &:hover {
      & > td {
        border-top: 1px solid rgba($cardBgHover, 0);
      }

      & + tr {
        & > td {
          border-top: 1px solid rgba($cardBgHover, 0);
        }
      }

      // td {
      //   &::after {
      //     background: solidColor($cardBg);
      //   }
      // }
    }
  }
}

.overlap-list.open{
  background: solidColor($cardBg);
  backdrop-filter: none;
  position: absolute;
  top: 100%;
  left: auto;
  right: rem(6px);
  z-index: 1000;
  width: auto;
  max-height: 30vh;

  &.out-of-table {
    position: fixed;
    width: auto;
    right: auto;
  }

  li {
    button {
      padding: rem(8px);
    }
    img {
      margin-right: rem(8px);
    }
  }
}