.contained {
  max-width: 1600px !important;
  margin: 0 auto !important;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
  margin-left: rem(-8px);
  margin-right: rem(-8px);

  @media screen and (max-width: $mobileMax) {
    flex-wrap: wrap;
  }

  .pb-medium {
    padding-bottom: rem(8px);
  }

  .pb-large {
    padding-bottom: rem(16px);
  }

  .mr-small {
    margin-right: rem(4px);
  }

  .mr-medium {
    margin-right: rem(8px);
  }
  
  .column {
    flex-basis: 33.33%;
    padding: rem(8px);

    &.smallest {
        flex-basis: 16.66%;
    }

    &.smaller {
        flex-basis: 20%;
    }

    &.small {
        flex-basis: 25%;
    }

    &.medium {
        flex-basis: 50%;
    }

    &.large {
        flex-basis: 66.66%;
    }
  }

  &:not(.no-reflow) {
    & > .column {
      @media screen and (max-width: $mobileMax) {
        flex-basis: 100%;
      }

      &.smallest {
        @media screen and (max-width: $mobileMax) {
          flex-basis: 100%;
        }
      }

      &.smaller {
        @media screen and (max-width: $mobileMax) {
          flex-basis: 100%;
        }
      }

      &.small {
        @media screen and (max-width: $mobileMax) {
          flex-basis: 100%;
        }
      }

      &.medium {
        @media screen and (max-width: $mobileMax) {
          flex-basis: 100%;
        }
      }

      &.large {
        @media screen and (max-width: $mobileMax) {
          flex-basis: 100%;
        }
      }
    }
  }

  .stretch {
    display: flex;
  }

  .flex-v-center {
    display: flex;
    align-items: center;
  }

  .flex-v-start {
    display: flex;
    align-items: start;
  }

  .flex-v-end {
    display: flex;
    align-items: end;
  }

  .flex-v-stretch {
    display: flex;
    align-items: stretch;
  }

  .flex-h-center {
    display: flex;
    justify-content: center;
  }
  
  .flex-h-start {
    display: flex;
    justify-content: flex-start;
  }
  
  .flex-h-end {
    display: flex;
    justify-content: flex-end;
  }

  &.fill {
    width: auto;
    
    & > .column {
        flex-basis: 100%;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.squeeze {
    & > .column {
      flex-basis: auto;
    }
  }

  &.tight {
    margin-left: rem(-4px);
    margin-right: rem(-4px);

    & > .column {
      padding: rem(4px);

    }
  }

  &.tight-top {
    & > .column {
      padding-top: rem(4px);
    }
  }

  &.tight-bottom {
    & > .column {
      padding-bottom: rem(4px);
    }
  }

  &.tightest {
    margin-left: rem(-1.5rem);
    margin-right: rem(-1.5rem);

    & > .column {
      padding: rem(1.5rem);
    }
  }

  &.tightest-x {
    margin-left: rem(-1.5rem);
    margin-right: rem(-1.5rem);

    & > .column {
      padding-left: rem(1.5rem);
      padding-right: rem(1.5rem);
    }
  }

  &.tightest-top {
    & > .column {
      padding-top: 0;
    }
  }

  &.tightest-bottom {
    & > .column {
      padding-bottom: 0;
    }
  }
}

td,
th {
  .flex-row {
    .column {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

th,
td {
  .flex-row {
    width: auto;
  }
}

.hidden {
  display: none !important;
}

@media screen and (min-width: $desktopMin) {
  .visible-tablet {
    display: none;
  }
}

@media screen and (max-width: $tabletMax) {
  .visible-desktop {
    display: none !important;
  }
}

@media screen and (max-width: $mobileMax) {
  .hidden-mobile {
		display: none !important;
	}
}
