.main-component {
    padding-top: rem(56px);
    padding-bottom: rem(16px);
    padding-left: rem(16px);
    padding-right: rem(16px);

    .topbar-component ~ .sidebar-component ~ &,
    .sidebar-component ~ .topbar-component ~ & {
        border-top-left-radius: 0;

        // &::before,
        // &::after {
        //     content: "";
        //     display: block;
        //     background: rgba($navBg, 1);
        //     width: rem(12px);
        //     height: rem(12px);
        //     position: fixed;
        //     left: rem(148px);
        //     top: rem(48px);
        //     z-index: 0;
        // }

        // &::after {
        //     background: rgba($appBgColor, 1);
        //     border-top-left-radius: rem(12px);
        //     z-index: 1;
        // }

        &::before,
        &::after {
            content: "";
            display: block;
            background-image: $doubleNavCorner;
            background-size: cover;
            width: rem(12px);
            height: rem(12px);
            position: fixed;
            left: rem(148px);
            top: rem(48px);
            z-index: 0;

            @media screen and (max-width: $tabletMax) {
                left: 0;
            }
        }
    }
    
    .app-like & {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0;

        @media screen and (max-width: $tabletMax) {
            left: rem(16px);
        }
    }

    .app-like .sidebar-component ~ & {
        left: rem(161px);
        
        @media screen and (max-width: $tabletMax) {
            left: rem(16px);
        }
    }

    .app-like .topbar-component ~ & {
        top: rem(48px);
    }
}