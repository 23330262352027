textarea {
    @include conduit-input;
    padding-top: rem(16px);
    min-height: rem(100px);
    max-height: rem(300px);

    scrollbar-width: auto;

    &:focus {
        padding-top: rem(16px);
    }
    
    &[disabled],
    &.disabled {
        resize: none;
    }
}