@mixin basebar {
    background: rgba($navBg, 1);
    bottom: 0;
    color: rgba($gray100, 1);
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: rem(148px);
    z-index: 999999;
    
    .bar-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      overflow: auto;
    
      .bar-content-start {
        align-items: center;
        display: flex;
      }
    
      .bar-content-end {
        display: flex;
        align-items: center;
      }
    }
  
    .brand-wrapper {
      margin: rem(4px) rem(8px);
      font-size: 0;
    }
  
    .brand {
      width: 100%;
    }
  
    .brand-contain-h,
    .brand-contain-v {
      height: rem(32px);
      margin-right: rem(8px);
      object-fit: contain;
      object-position: center center;
      width: rem(32px);
  
      &:last-child {
        margin-right: 0;
      }
    }
  
    .brand-contain-h {
      height: auto;
    }
  
    .brand-contain-v {
      width: auto;
    }
  }