.card-board-component {
  min-height: calc(100vh - 58px);
  margin-right: 0;
  user-select: none;
  
  .card-board-list {
    min-width: 320px;
    .scroll-content {
      max-height: calc(100vh - 160px);
      overflow: auto;
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
  }
}