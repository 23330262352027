@media print {
  html {
    font-size: 10px;
    // min-height: 100%;
    // height: auto;

  }

  // body {
  //   min-height: 100vh;
  // }

  // #root,
  // .App,
  // .scroll-content {
  //   min-height: 100vh;
  //   background: transparent;
  // }

  // .scroll-content {
  //   display: flex;
  //   flex-direction: column;

  //   & > .flex-row {
  //     &:last-child {
  //       flex-basis: 100%;
  //       background: red;
  //       flex-grow: 1;
        
  //       .column {
  //         display: flex;
  //         flex-direction: column;
          
  //         .card {
  //           flex-basis: 100%;
  //           flex-grow: 1;
  //         }
  //       }
  //     }
  //   }
  // }

  .navigation-component {
    display: none;
  }

  .main-component {
    position: static;
    background: rgba($whiteStatic, 1);
  }

  .app-like {
    .main-component {
      &:after,
      &:before {
        display: none !important;
      }
    }

    .topbar-component {
      &~.main-component {
        top: 0;
      }
    }

    .sidebar-component {
      &~.main-component {
        left: 0;
      }
    }
  }
  
  input {
    border: 0 !important;
    background: transparent;
    text-align: inherit;
    padding: 0;
    -moz-appearance: textfield !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }

  .tableview-component {
    table {
      margin-top: 0;
    
      .na {
        border: 3px solid rgba(var(--gray700), 0.9);
      }
    }
  }

  p[contentEditable] {
    font-size: 8px;
  }

  [contentEditable] {
    background: transparent !important;
    font-size: 10px;
    &:empty {
      display: none;
    }
  }

  .invoice-logo {
    height: 50px !important;
  }

  .card {
    border: 0;
    padding: 0;
    background: transparent;

    [contentEditable] {
      padding-left: 0;
      
      &:empty {
        display: none;
      }
    }
  }

  .progress-bar {
    display: none;
  }

  .toolbar-component {
    display: none;
  }

  .header {
    display: none;
  }

  .page-title {
    display: none;
  }

  .v-scroll,
  .scroll-content {
    overflow: visible;
    padding: 0;
    margin: 0;
    max-height: none;
  }

  [contenteditable] {
    border: 0;
  }

  input {
    appearance: none;
  }

  .hide-print {
    display: none;
  }

  .show-print {
    display: inline !important;
  }

  .flash-card-widget-component {
    margin-top: 2rem;
    .column {
      &.small {
        flex-basis: 100%;
      }
    }

    .flash-card {
      padding: 0;
      margin-bottom: 1rem;
      text-align: left;

      .content {
        position: relative;
        align-items: flex-start;
        left: 0;
      }
    }

    .faint-text {
      font-weight: bold;
      font-size: 1.2rem;
      color: rgb(var(--gray100));
    }

    .primary-title {
      font-size: 1.6rem;
    }
  }

  .flex-row {
    &.squeeze {
      > .column {
        select {
          display: none;
        }
      }
    }
  }

  .print-extra-info {
    padding: 0.5rem 1rem;
  }

  .primary-title {
    font-size: 1.2rem;
  }

  .primary-title,
  .ww-subtitle {
    font-weight: bold;
  }
}

.show-print {
  display: none;
}