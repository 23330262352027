::-moz-selection { background: rgba($gray600, 1); }
::selection { background: rgba($gray600, 1); }

::-moz-focus-inner { border: 0; }
:focus { outline: none;}

html {
  -webkit-tap-highlight-color: transparent;
}

.CONDUIT {
	min-height: 100vh;
	background-color: rgba($appBgColor, 1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	
	&.app-like {
		display: block;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

button:focus {
	outline: none;
}

*, *::before, *::after {
	box-sizing: border-box;
}

img {
	user-select: none;
}

img[alt] {
	font-size: 0;
}