input[type=checkbox] {
  appearance: none;
  margin: 0 rem(4px) 0 0;
  background-color: transparent;
  height: rem(20px);
  width: rem(20px);
  border-radius: rem(5px);
  border: 1px solid rgba($borderColor, 1);
  vertical-align: middle;
  position: relative;

  & + label {
    padding-bottom: 0;
    vertical-align: middle;
    display: inline;
  }

  &::before {
    content: "";
    width: rem(11px);
    height: rem(9px);
    display: block;
    margin: 0 auto;
    top: 50%;
    transition: none;
    box-shadow: inset 1em 1em rgba($borderColor, 1);
    position: relative;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    opacity: 0;
    z-index: 0;
    transform: translateY(-50%);
  }

  &:checked,
  &:indeterminate {
    background: rgba($primary, 1);
    border: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      right: 1px;
      left: 1px;
      bottom: 1px;
      z-index: 1;
      background-color: rgba($primary, 1);
      animation: .2s recedeRight linear forwards;
      border-radius: rem(6px);
    }
  }

  &:checked {
    &::before {
      opacity: 1;
    }
  }

  &:indeterminate {
    &::before {
      width: rem(8px);
      height: rem(8px);
      clip-path: polygon(100% 35%,100% 60%,0% 60%,0% 35%);
      transition: .1s transform linear, .1s opacity 0.05s linear;
      transform: translateY(-50%) translateX(0);
      opacity: 1;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 1px rgba($white, 1), 0 0 0 3px rgba($gray100, 1);
    z-index: 10;
  }

  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: .3;

    + label {
      pointer-events: none;
      opacity: .3;
    }
  }

  &:hover {
    background: rgba($gray1000, 1);
    
    &:checked,
    &:indeterminate {
      background: rgba($primaryDark, 1);

      &::after {
        background: rgba($primaryDark, 1);
      }

      &:active {
        background: rgba($primary, .7);
        border: 1px solid rgba($primary, 1);
      }
    }
      
    &:active {
      border: 1px solid rgba($borderColorFocus, 1);
    }
  }
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;

  .form-group {
    margin-right: rem(16px);

    &:last-child {
      margin-right: 0;
    }
  }
}