.toggle {
    position: relative;

    input[type="checkbox"] {
      opacity: 0;
      width: 0;
      margin: 0;
      position: absolute;
      pointer-events: none;
  
      &:focus-visible + label {
        box-shadow: $focusOutline;
        z-index: 10;
      }
  
      &:checked {
        & + label {
          &::after {
            box-shadow: 0 rem(1px) rem(5px) rgba($gray300, 0);
            background: rgba($primary, 1);
            height: rem(16px);
            border: 0;
          }
  
          &::before {
            background-color: rgba($white, 1);
            right: rem(9px);
          }
        }
      }
    }

    input[type="checkbox"] + label,
    label {
      background: rgba($cardBg, .5);
      border-radius: rem(6px);
      border: 1px solid rgba($borderColor, .6);
      user-select: none;
      cursor: pointer;
      font-size: rem(14px);
      display: inline-block;
      line-height: 1;
      margin: 0 rem(8px) 0 0;
      padding: rem(7.4px) rem(48px) rem(7.4px) rem(12px);
      position: relative;
      transition: .2s $animationEasing all, 0.01s linear box-shadow;
      white-space: nowrap;
  
      &:last-child {
        margin-right: 0;
      }
  
      &::after {
        background: rgba($cardBg, 0);
        border: 1px solid rgba($borderColor, .9);
        border-radius: 500px;
        content: "";
        display: block;
        height: rem(18px);
        position: absolute;
        right: rem(8px);
        top: 50%;
        transform: translateY(-50%);
        width: rem(32px);
        z-index: 1;
        transition: .2s ease right, .2s ease background, .2s ease box-shadow;
      }
  
      &::before {
        content: "";
        display: block;
        height: rem(14px);
        width: rem(14px);
        border-radius: 500px;
        position: absolute;
        background: rgba($toggleBall, .9);
        right: rem(24px);
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        transition: .2s ease right, .2s ease background;
      }
  
      &:hover {
        background: rgba($cardBgHover, .5);
        .fa,
        .fas,
        .far {
          color: rgba($gray100, 1);
          text-shadow: none;
        }
      }
  
      &:active {
        background: rgba($cardBgActive, .5);
        border: 1px solid rgba($borderColorFocus, 1);
      }
    }
  
    [disabled] + label,
    &.disabled {
      pointer-events: none;
      background: rgba($inputBg, .2);
      opacity: .6;

      span {
        cursor: default;
      }
    }
    
    &.multiline {
      margin-bottom: rem(3px);
  
      .state {
        position: absolute;
        top: 50%;
        font-size: rem(14px);
        transform: translateY(-50%);
        left: calc(100% - 80px);
        text-align: left;
      }
  
      input[type="checkbox"] + label,
      label {
        margin: 0;
        padding: rem(13px) rem(128px) rem(13px) rem(16px);
        font-size: rem(15px);
        line-height: normal;
        width: 100%;
        border: 1px solid rgba($cardBorderColor, .6);
        min-height: rem(68px);
        white-space: normal;
        display: flex;
        font-weight: 400;
        justify-content: center;
        flex-direction: column;
  
        &::after {
          top: 50%;
          transform: translateY(-50%) translateX(-10px);
        }
  
        &::before {
          top: 50%;
          transform: translateY(-50%) translateX(-10px);
        }
  
        &:active {
          background: rgba($cardBgActive, .5);
          border: 1px solid rgba($borderColorFocus, 1);
        }
      }
    }
  
    .fa,
    .fas,
    .far {
      margin-right: rem(5px);
      
      &:last-child {
        margin-right: 0;
      }
    }
  
    span {
      vertical-align: middle;
    }
  }