/* no color variables here, image ambient specific colors */

.welcome-widget-component {
    .bg-helper {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }

    .card {
        border: 1px solid rgba(#ffffff, .3);
    }

    .card-list-component .story,
    .card-list-component .primary-title {
        color: rgba($whiteStatic, .9);
    }

    .info-card {
        position: relative;
        border: 0;
        overflow: hidden;
        min-height: 300px;
        padding: 0;

        &.neutral {
            background-image: transparent;

            .bg-helper,
            .blur-content {
                background: top left no-repeat;
                background-size: cover;
            }
        }

        &.day {
            .bg-helper,
            .blur-content {
                background: top left no-repeat;
                background-size: cover;
            }
        }

        &.dawn {
            .bg-helper,
            .blur-content {
                background: 100% 25% repeat-x;
                background-size: cover;
            }
        }

        &.night {
            .bg-helper,
            .blur-content {
                background: top left no-repeat;
                background-size: cover;
            }
        }
    }

    .content-wrapper {
        z-index: 150;
        position: relative;
        z-index: 3;
        width: 33%;
        border-radius: rem(16px);
        padding: rem(16px);
        margin: rem(16px);
        min-height: 268px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: $mobileMax) {
            width: 66%;
        }

        span {
            text-shadow: 0 0 1px solid rgba($blackStatic, 1);
            font-weight: normal;
            font-size: rem(14px);
        }

        .primary-title {
            color: rgba($whiteStatic, .9);
            text-shadow: 0 0 1px solid rgba($blackStatic, 1);
        }

        .body-text {
            color: rgba($whiteStatic, 1);
            width: calc(100% - 32px);
            font-size: rem(14px);
        }
    }

    &.short {
        .card {
            min-height: 200px;
        }

        .content-wrapper {
            min-height: 168px;
        }

        .blur-content {
            min-height: auto;
        }
    }

    &.tall {
        .card {
            min-height: 400px;
        }

        .content-wrapper {
            min-height: 368px;
        }

        .blur-content {
            min-height: auto;
        }
    }

    &.slider-component {
        opacity: 0;

        &.slick-initialized {
            opacity: 1;
            transition: opacity .2s ease-out;
        }
    }
}