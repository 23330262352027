.CONDUIT {
  &.purple-accent {
    // --appBgColorAccent:              #{convertToRGB(#261a35)};
    --primary:                  #{convertToRGB(#9242d3)};
    --primaryDark:              #{convertToRGB(#802faf)};
    --primaryLight:             #{convertToRGB(#b85be4)};

    &.show-mood {
      background-image: linear-gradient(135deg, #f4f0f6 0%,#e9d5f7 100%);

      --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'fbf7fe'}'/%3E%3C/svg%3E%0A");

      --thumbnailBg:              #{convertToRGB(#cfc1d6)};
      --inputBg:                  #{convertToRGB(#fcf7ff)};
      --inputBgHover:             #{convertToRGB(#f7f0fb)};
      --inputBgHover2:            #{convertToRGB(#f3e9f9)};
      --borderColor:              #{convertToRGB(#bda4c9)};
      --borderColorFocus:         #{convertToRGB(#d7c3e3)};
      --cardBorderColor:          #{convertToRGB(#bca4cb)};
      
      --cardBg:                   #{convertToRGB(#fcf7ff)};
      --cardBgHover:              #{convertToRGB(#f4ecf9)};
      --cardBgActive:             #{convertToRGB(#ebe2f1)};

      --tableHeadBg:              #{convertToRGB(#eddef5)};
      --tableRowBg:               #{convertToRGB(#e6d5ef)};
      --tableRowHR:               #{convertToRGB(#ebe3f0)};

      --navBg:                    #{convertToRGB(#fbf7fe)};
      --navItemBgHover:           #{convertToRGB(#f2e9f6)};
      --navItemBgActive:          #{convertToRGB(#e6daec)};

      --weekendBg:                #{convertToRGB(#eddef5)};
      --weekendBgHover:           #{convertToRGB(#e4d4ee)};
      
      --faintText:                #{convertToRGB(#614c6a)};
      --toggleBall:               #{convertToRGB(#bca2cb)};
    }
    
    &.dark-theme {
      &.show-mood {
        background-image: linear-gradient(135deg, #28192e 0%,#221e24 100%);

        --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'1b121e'}'/%3E%3C/svg%3E%0A");

        --gray1100:                 #{convertToRGB(#2b2130)};
        --gray1000:                 #{convertToRGB(#322737)};
        --gray900:                  #{convertToRGB(#3f2d44)};
        --gray800:                  #{convertToRGB(#392d3f)};
        --gray700:                  #{convertToRGB(#5a4364)};
        --gray600:                  #{convertToRGB(#73567c)};
        --gray300:                  #{convertToRGB(#b194bd)};
        --gray100:                  #{convertToRGB(#f9f0ff)};

        --thumbnailBg:              #{convertToRGB(#594364)};
        --inputBg:                  #{convertToRGB(#43364d)};
        --inputBgHover:             #{convertToRGB(#4d3f59)};
        --inputBgHover2:            #{convertToRGB(#433a4e)};
        --borderColor:              #{convertToRGB(#4a3c58)};
        --borderColorFocus:         #{convertToRGB(#372c41)};
        --cardBorderColor:          #{convertToRGB(#150c1a)};
        --cardBg:                   #{convertToRGB(#392f43)};
        --cardBgHover:              #{convertToRGB(#453752)};
        --cardBgActive:             #{convertToRGB(#32283c)};

        --tableHeadBg:              #{convertToRGB(#392f46)};
        --tableRowBg:               #{convertToRGB(#45364e)};
        --tableRowHR:               #{convertToRGB(#392f43)};

        --navBg:                    #{convertToRGB(#1b121e)};
        --navItemBgActive:          #{convertToRGB(#37283c)};
        --navItemBgHover:           #{convertToRGB(#3e2f43)};
        
        --weekendBg:                #{convertToRGB(#3b2c41)};
        --weekendBgHover:           #{convertToRGB(#493550)};
        --faintText:                #{convertToRGB(#b8b0c1)};
        --toggleBall:               #{convertToRGB(#9488a1)};
      }
    }
  }
}