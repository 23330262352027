.booking-legend-component {
  white-space: normal;

  .marker-wrapper {
    padding-bottom: rem(16px);
  }
  
  @each $day, $color in $dayTypes {
    .#{$day} { 
      position: relative;
      padding: rem(8px);
      text-align: center;

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 4px;
        width: auto;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba($color, 1);
        z-index: 1;
      }

      .ghost-button {
        border-radius: 500px;
        border: 1px solid rgba($borderColor, 1);
        background: rgba($inputBg, 1);
        box-shadow: 0 0 0 2px rgba($white, .9), 0 0 0 5px rgba($color, 1);
        font-size: rem(14px);
        width: rem(31px);
        height: rem(31px);
        position: relative;
        z-index: 2;
        padding: 0;
        cursor: default;
        pointer-events: none;
        margin-top: 0;
        margin-bottom: 0;

        &:not(:hover) {
          color: rgba($black, 1);
        }
      }
      
      &.today {
        &::before {
          width: calc(100% - 4px);
          left: rem(2px);
        }
      }
    }
  }

  .type-label {
    color: rgba($faintText, 1);
    display: block;
    font-size: rem(13px);
    text-align: center;
    margin-top: rem(8px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
  