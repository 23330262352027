.CONDUIT {
  &.red-accent {
    --primary:                  #{convertToRGB(#ea1919)};
    --primaryDark:              #{convertToRGB(#c11515)};
    --primaryLight:             #{convertToRGB(#ff3333)};
    

    &.show-mood {
      background-image: linear-gradient(135deg, #f6f0f0 0%,#f7d5d5 100%);

      --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'fef7f7'}'/%3E%3C/svg%3E%0A");

      --thumbnailBg:              #{convertToRGB(#d6c1c1)};
      --inputBg:                  #{convertToRGB(#fff7f7)};
      --inputBgHover:             #{convertToRGB(#fbf0f0)};
      --inputBgHover2:            #{convertToRGB(#f9e9e9)};
      --borderColor:              #{convertToRGB(#c9a4a4)};
      --borderColorFocus:         #{convertToRGB(#e3c3c3)};
      --cardBorderColor:          #{convertToRGB(#cba4a4)};
      
      --cardBg:                   #{convertToRGB(#fff7f7)};
      --cardBgHover:              #{convertToRGB(#f9ecec)};
      --cardBgActive:             #{convertToRGB(#f1e2e2)};

      --tableHeadBg:              #{convertToRGB(#f5dede)};
      --tableRowBg:               #{convertToRGB(#efd5d5)};
      --tableRowHR:               #{convertToRGB(#f0e3e3)};

      --navBg:                    #{convertToRGB(#fef7f7)};
      --navItemBgHover:           #{convertToRGB(#f6e9e9)};
      --navItemBgActive:          #{convertToRGB(#ecdada)};

      --weekendBg:                #{convertToRGB(#f5dede)};
      --weekendBgHover:           #{convertToRGB(#eed4d4)};
      
      --faintText:                #{convertToRGB(#6a4c4c)};
      --toggleBall:               #{convertToRGB(#cba2a2)};
    }
    

    &.dark-theme {
      &.show-mood {
        background-image: linear-gradient(135deg, #341313 0%,#251c1c 100%);

        --doubleNavCorner:          url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0L6.99382e-07 -6.99382e-07L0 8C3.86258e-07 3.58173 3.58179 -3.86252e-07 8 0Z' fill='%23#{'1e1212'}'/%3E%3C/svg%3E%0A");

        --gray1100:                 #{convertToRGB(#302121)};
        --gray1000:                 #{convertToRGB(#372727)};
        --gray900:                  #{convertToRGB(#442d2d)};
        --gray800:                  #{convertToRGB(#3f2d2d)};
        --gray700:                  #{convertToRGB(#644343)};
        --gray600:                  #{convertToRGB(#7c5656)};
        --gray300:                  #{convertToRGB(#bd9494)};
        --gray100:                  #{convertToRGB(#fff0f0)};

        --thumbnailBg:              #{convertToRGB(#644343)};
        --inputBg:                  #{convertToRGB(#4d3636)};
        --inputBgHover:             #{convertToRGB(#593f3f)};
        --inputBgHover2:            #{convertToRGB(#4e3a3a)};
        --borderColor:              #{convertToRGB(#583c3c)};
        --borderColorFocus:         #{convertToRGB(#412c2c)};
        --cardBorderColor:          #{convertToRGB(#1a0c0c)};
        --cardBg:                   #{convertToRGB(#432f2f)};
        --cardBgHover:              #{convertToRGB(#523737)};
        --cardBgActive:             #{convertToRGB(#3c2828)};
        
        --tableHeadBg:              #{convertToRGB(#462f2f)};
        --tableRowBg:               #{convertToRGB(#4e3636)};
        --tableRowHR:               #{convertToRGB(#432f2f)};

        --navBg:                    #{convertToRGB(#1e1212)};
        --navItemBgActive:          #{convertToRGB(#3c2828)};
        --navItemBgHover:           #{convertToRGB(#432f2f)};
        
        --weekendBg:                #{convertToRGB(#412c2c)};
        --weekendBgHover:           #{convertToRGB(#503535)};
        --faintText:                #{convertToRGB(#c1b0b0)};
        --toggleBall:               #{convertToRGB(#a18888)};
      }
    }
  }
}