.icon-list-component {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  
  li {
    margin-right: rem(8px);
  }
}