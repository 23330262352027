.error-list {
    padding-left: 0;
    text-align: left;
    transition: .2s $animationEasing all, 0.01s linear box-shadow;
    position: relative;
    z-index: 100;
    overflow: auto;
    color: rgba($danger, 1);
  
    li {
        background: rgba($danger, 1);
        color: rgba($white, 1);
        padding: rem(9.5px) rem(16px);
        border-radius: rem(6px);
        font-size: rem(11px);
        font-weight: 700;
        margin-bottom: rem(4px);
        margin-top: rem(4px);
    }
  
    &.light {
      li {
        background: transparent;
        padding: 0;
        color: rgba($danger, 1);
      }
    }
  }
  