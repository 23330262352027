.footer {
    .footer-nav {
        padding: rem(8px) 0;
        list-style-type: none;
        display: flex;
        
        li {
            padding-right: rem(8px);
            font-size: rem(13px);
        }
    }
}