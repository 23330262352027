.sidebar-component {
  @include basebar;

  @media screen and (max-width: $tabletMax) {
    transform: translateX(-100%);
    transition: .2s ease transform;

    &.open {
      transform: translateX(0);
    }

    &.offset-start-tablet {
      top: rem(48px);

      .bar-content {
        height: calc(100vh - 48px);
      }
    }
  }

  .bar-content {
    height: 100vh;
  }

  .brand,
  .brand-contain-h,
  .brand-contain-v {
    margin: rem(8px) 0;
  }

}