@use "sass:math";

input[type=range] {
  height: rem(29px);
  -webkit-appearance: none;
  margin: 10px 0;
  background: transparent;
  padding: 0 rem(5px);

  &::-webkit-slider-runnable-track {
    @include range-track;
  }

  &::-webkit-slider-thumb {
    @include range-thumb;
    margin-top: -5px;
    position: relative;
  }

  &::-moz-range-track {
    @include range-track;
  }

  &::-moz-range-progress {
    @include range-progress;
  }

  &::-moz-range-thumb {
    @include range-thumb;
  }

  @for $i from 1 through 100 {
    &[value="#{$i}"] {
      &::-webkit-slider-runnable-track {
        background-image: linear-gradient(to right, rgba($primary, 1) #{$i}#{'%'}, rgba($cardBgHover, 1) #{$i}#{'%'});
      }
    }
  }

  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: .3;
  }

  &:focus-visible {
    border-radius: 6px;
    box-shadow: $focusOutline;
    z-index: 10;
  }
}