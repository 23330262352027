@mixin range-thumb {
    box-shadow: 0 0 0 14px rgba($primary, 1) inset,
                0 0 0 0 rgba($primary, 1),
                0 0 0 5px rgba($inputBg, 1),
                0 0 0 6px rgba($borderColor, 1);
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: rgba($primary, 1);
    border: 0;
    transition: .1s linear box-shadow;
  
    &:hover {
      box-shadow: 0 0 0 14px rgba($primary, 1) inset,
                  0 0 0 2px rgba($primary, 1),
                  0 0 0 5px rgba($inputBg, 1),
                  0 0 0 6px rgba($borderColor, 1);
    }
  
    &:active {
      background: rgba($primaryDark, 1);
      box-shadow: 0 0 0 14px rgba($primaryDark, 1) inset,
                  0 0 0 0 rgba($primaryDark, 1),
                  0 0 0 5px rgba($inputBg, 1),
                  0 0 0 6px rgba($borderColor, 1);
    }
  }
  
  @mixin range-track {
    width: 100%;
    height: rem(6px);
    animate: 0.2s;
    background: rgba($cardBgHover, 1);
    border-color: transparent;
    border-radius: 500px;
    color: transparent;
  }
  
  @mixin range-progress {
    height: rem(6px);
    animate: 0.2s;
    background: rgba($primary, 1);
    border-color: transparent;
    border-radius: 500px;
    color: transparent;
  }